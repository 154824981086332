import React from 'react';

export interface ITrackingContext {
  reportType: string | null;
  reportSubType: string | null;
  tabName: string;
  vendorId: string | null;
  vendorName: string | null;
  country: string | null;
  businessModel: string | null;
}

export const TrackingContext = React.createContext<ITrackingContext | null>(null);
