import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { EmbedReportResponse } from 'Domain/NewEmbedReport';

export type getEmbedReportProps = {
  lang: string;
  workspaceId: string;
  reportId: string;
  permissionId?: string;
  vendorId?: string;
  country: string;
};

export const getEmbedReport = async ({
  lang,
  workspaceId,
  reportId,
  permissionId,
  vendorId,
  country,
}: getEmbedReportProps): Promise<EmbedReportResponse> => {
  const baseUrl = getAPIBaseURL(APIEndpoint.GET_EMBED_REPORT, country);

  const headers: Record<string, string> = {};
  if (country) {
    headers.country = country;
  }

  const response = await Api.get({
    url: 'config/embed-report',
    baseURL: baseUrl,
    params: {
      lang,
      workspaceId,
      reportId,
      permissionId,
      vendorId,
    },
    headers,
  });

  return response.data;
};
