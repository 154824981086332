import React from 'react';
import { ISvgRenderProps } from './SvgRender.d';

const SvgRender = ({
  svg,
  width,
  height,
  alt,
  className = 'svg-render',
  testid = 'data-test-id-svg-render',
}: ISvgRenderProps): React.ReactElement => (
  <div className={className}>
    <img data-testid={testid} src={svg} alt={alt} width={width} height={height} />
  </div>
);

export default SvgRender;
