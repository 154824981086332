import { useReports } from 'Hooks/useReports/useReports';
import React from 'react';
import { PowerBIReportEvent } from './usePowerBi.types';

interface ReportEventState {
  firstRender: boolean;
  time: {
    init: number;
    dataLoaded: number;
    interaction: number;
    renderingStarted: number;
  };
}

interface EventInfo {
  name: PowerBIReportEvent;
  data: {
    report: string;
    company: string;
    time: number;
  };
}

const initialEventRef: ReportEventState = {
  firstRender: false,
  time: {
    init: 0,
    dataLoaded: 0,
    interaction: 0,
    renderingStarted: 0,
  },
};

const usePowerBIReportEvents = (): {
  trackReportEvent: (eventName: PowerBIReportEvent) => void;
} => {
  const { workspaceSelected, reportBySlug } = useReports();

  const eventsRef = React.useRef<ReportEventState>({ ...initialEventRef });

  const handleInitEvent = () => {
    eventsRef.current.firstRender = false;
    eventsRef.current.time = { ...initialEventRef.time, init: Date.now() };
  };

  const handleInteractionEvent = () => {
    eventsRef.current.time.interaction = Date.now();
  };

  const handleDataLoadedEvent = (eventInfo: EventInfo) => {
    eventsRef.current.time.dataLoaded = Date.now();
    eventInfo.data.time = eventsRef.current.time.dataLoaded - eventsRef.current.time.init;
  };

  const handleRenderingStartedEvent = (eventInfo: EventInfo) => {
    eventsRef.current.time.renderingStarted = Date.now();
    eventInfo.data.time =
      eventsRef.current.time.renderingStarted - eventsRef.current.time.dataLoaded;
    if (eventsRef.current.time.dataLoaded === 0) eventInfo.data.time = 0;
  };

  const handleFirstRenderEvent = (eventInfo: EventInfo) => {
    eventsRef.current.firstRender = true;
    eventInfo.data.time = Date.now() - eventsRef.current.time.renderingStarted;
    if (eventsRef.current.time.renderingStarted === 0) eventInfo.data.time = 0;
  };

  const handleInteractionRenderedEvent = (eventInfo: EventInfo) => {
    eventInfo.name = PowerBIReportEvent.INTERACTION_RENDERED;
    eventInfo.data.time = Date.now() - eventsRef.current.time.interaction;
    eventsRef.current.time.interaction = 0;
  };

  const trackReportEvent = (eventName: PowerBIReportEvent) => {
    const eventInfo: EventInfo = {
      name: eventName,
      data: {
        report: reportBySlug?.trackingId || '',
        company: workspaceSelected.vendorName || '',
        time: 0,
      },
    };

    switch (eventName) {
      case PowerBIReportEvent.INIT:
        handleInitEvent();
        return;
      case PowerBIReportEvent.INTERACTION:
        handleInteractionEvent();
        return;
      case PowerBIReportEvent.DATA_LOADED:
        handleDataLoadedEvent(eventInfo);
        break;
      case PowerBIReportEvent.RENDERING_STARTED:
        handleRenderingStartedEvent(eventInfo);
        break;
      case PowerBIReportEvent.REPORT_RENDERED:
        if (!eventsRef.current.firstRender) {
          handleFirstRenderEvent(eventInfo);
        } else {
          if (eventsRef.current.time.interaction === 0) return;
          handleInteractionRenderedEvent(eventInfo);
        }
        break;
      default:
        return;
    }

    window.newrelic?.addPageAction(eventInfo.name, eventInfo.data);
  };

  return { trackReportEvent };
};

export default usePowerBIReportEvents;
