import { ReportsPageContext } from 'Context/ReportsPageContext';
import useAppEvents from 'Hooks/segment/useAppEvents/useAppEvents';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import useReportsMFEConfig from 'Services/GetReportsMFEConfig/useReportsMFEConfig';
import { SidebarUtilitiesItem } from 'admin-portal-shared-services';
import { usePartnerSidebarUtils } from 'partner-mfe-shared-features';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

export default function useSidebarUtils(): SidebarUtilitiesItem[] {
  const { formatMessage } = useIntl();
  const { triggerMenuInteraction } = useAppEvents();

  const { vendorId, selectedVendor, selectedCountry } = useSelectedVendor();
  const reportsPageContext = useContext(ReportsPageContext);
  const partnerSidebarUtils = usePartnerSidebarUtils();
  const reportsMFEConfig = useReportsMFEConfig({ vendorId, country: selectedCountry });

  return useMemo(() => {
    const utils: SidebarUtilitiesItem[] = [];

    return utils;
  }, [
    reportsMFEConfig.isLoading,
    reportsMFEConfig.isError,
    selectedVendor.data,
    partnerSidebarUtils,
    formatMessage,
    vendorId,
    selectedCountry,
    reportsPageContext?.selectedMenuItem?.slug,
    triggerMenuInteraction,
    reportsMFEConfig.data?.menuItems,
  ]);
}
