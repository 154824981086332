import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getBTGuideService from './getBTGuideService';

export const useBTGuideById = (
  vendorId: string | null | undefined,
  country: string
): UseQueryResult<any> => {
  const result = useQuery({
    queryKey: ['vendors', vendorId, country],
    queryFn: () => getBTGuideService({ vendorId, country }),
    enabled: true,
    refetchOnWindowFocus: false,
  });

  return result;
};
