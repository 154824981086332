import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  marginBottom: '$6',
});

const Row = styled('div', {
  width: '100%',
  display: 'flex',
  gap: '$6',
  flexDirection: 'column',
  '& > div:nth-child(1)': {
    order: 2,
    width: '100%',
  },
  '& > div:nth-child(2)': {
    order: 1,
    width: '100%',
  },

  '@desktopSmall': {
    flexDirection: 'row',
    '& > div:nth-child(1)': {
      order: 1,
      width: 'calc(70% - 24px)',
    },
    '& > div:nth-child(2)': {
      order: 2,
      width: '30%',
    },
  },

  variants: {
    hasBTGuide: {
      false: {
        '& > div:nth-child(1)': {
          width: '100%',
        },
      },
    },
  },
});

const ContainerCarousel = styled('div', {
  '& > div > div > button': {
    top: 'calc(50% - 4px)',
  },
  '& ul': {
    marginBottom: '0px !important',
  },
  variants: {
    hideSpaces: {
      true: {
        '& li > div': {
          padding: '24px 2px 24px',
        },
      },
    },
  },
});

const CenterContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

export const Styled = {
  ContainerCarousel,
  Container,
  Row,
  CenterContainer,
};
