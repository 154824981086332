export enum PermissionRuleType {
  COUNTRY = 'Country',
  BUSINESS_MODEL = 'BusinessModel',
  VENDOR_ID = 'VendorID',
  MANUFACTURER_ID = 'ManufacturerID',
}

export type Permission = {
  id: string;
  name: string;
  thumbnail: {
    type: string;
    value: string;
  };
  rule?: {
    type: PermissionRuleType;
    value: string;
  };
  country?: string;
};

export enum WorkspaceRLSSchemaValue {
  VENDOR_ID = 'VendorId',
  COUNTRY = 'Country',
  LANGUAGE = 'Language',
  USER_ID = 'UserId',
}

export type Workspace = {
  id: string;
  slug: string;
  trackingId: string;
  title: Record<string, string>;
  description: Record<string, string>;
  permissions: Permission[];
  region?: string;
  rowLevelSecurity: {
    role: string;
    schema: string;
  };
};

export interface Workspaces {
  workspaces: Workspace[];
}

const createWorkspace = (
  id: string,
  slug: string,
  trackingId: string,
  title: string,
  permissionsId: string,
  permissionsName: string,
  permissionsThumbnailType: string,
  permissionsThumbnailValue: string,
  region: string,
  role: string,
  schema: string
): Workspace => ({
  id,
  slug,
  trackingId,
  title: {
    'id-ID': title,
    'en-US': title,
    'es-419': title,
    'pt-BR': title,
  },
  description: {
    'id-ID': '',
    'en-US': '',
    'es-419': '',
    'pt-BR': '',
  },
  permissions: [
    {
      id: permissionsId,
      name: permissionsName,
      thumbnail: {
        type: permissionsThumbnailType,
        value: permissionsThumbnailValue,
      },
    },
  ],
  region,
  rowLevelSecurity: {
    role,
    schema,
  },
});

export const MOCK_WORKSPACES: Workspaces = {
  workspaces: [
    createWorkspace(
      '111',
      'seller',
      'TEST',
      'Seller',
      '222',
      'Rule',
      'Icon',
      'AddTag',
      'Global',
      'TEST',
      'TEST'
    ),
    createWorkspace(
      '112',
      'buyer',
      'TEST2',
      'Buyer',
      '223',
      'Rule2',
      'Icon',
      'AddTag2',
      'Global',
      'TEST2',
      'TEST2'
    ),
  ],
};

export const MOCK_WORKSPACES_WITHOUT_PERMISSIONS: Workspaces = {
  workspaces: [
    {
      id: '111',
      slug: 'seller',
      trackingId: 'TEST',
      title: {
        'id-ID': 'Seller',
        'en-US': 'Seller',
        'es-419': 'Seller',
        'pt-BR': 'Seller',
      },
      description: {
        'id-ID': '',
        'en-US': '',
        'es-419': '',
        'pt-BR': '',
      },
      permissions: [],
      region: 'Global',
      rowLevelSecurity: {
        role: 'TEST',
        schema: 'TEST',
      },
    },
  ],
};