export const linkByCountry: { [country: string]: string } = {
  AR: 'https://partnersupportar.zendesk.com/hc/es-419/articles/11305897840539-Gu%C3%ADa-de-archivos-CSV',
  BR: 'https://partnersupportbr.zendesk.com/hc/pt-br/articles/11224370174999-Guia-de-arquivos-CSV',
  CO: 'https://partnersupportco.zendesk.com/hc/es/articles/11692125023629-Gu%C3%ADa-de-archivos-CSV',
  DO: 'https://partnersupportdr.zendesk.com/hc/es-419/articles/11223787378071-Gu%C3%ADa-de-archivos-CSV',
  EC: 'https://partnersupportec.zendesk.com/hc/es/articles/11882406502420-Gu%C3%ADa-de-archivos-CSV',
  PA: 'https://partnersupportpan.zendesk.com/hc/es-419/articles/11223847237015-Gu%C3%ADa-de-archivos-CSV',
  PE: 'https://partnersupportpe.zendesk.com/hc/es-419/articles/11559051388699-Gu%C3%ADa-de-archivos-CSV',
  PY: 'https://partnersupportpy.zendesk.com/hc/es-419/articles/11559056948891-Gu%C3%ADa-de-archivos-CSV',
  MX: 'https://partnersupportmx.zendesk.com/hc/es/articles/11882411191060-Gu%C3%ADa-de-archivos-CSV',
  ZA: 'https://partnersupportza.zendesk.com/hc/en-gb/articles/7192467309980-CSV-file-guide',
};
