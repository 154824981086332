/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IconName } from 'Components/IconsMap/IconsMap';
import { MenuItem } from 'Domain/NewMenuItem';
import { useIntl } from 'react-intl';

type CustomReportTab = {
  id: string;
  iconString: IconName;
  fullName: string;
  reportSlug: string;
  themeSlug: string;
};

export const GetReportTabs = (data: MenuItem[]) => {
  const { formatMessage } = useIntl();
  const SELECTED_LANGUAGE = formatMessage({ id: 'SELECTED_LANGUAGE' });
  const reportTabsWithIcon: CustomReportTab[] = [];

  data.forEach((menuItem) => {
    menuItem.reportTabs.forEach((reportTab) =>
      reportTabsWithIcon.push({
        id: reportTab.id,
        iconString: menuItem.iconString as IconName,
        fullName: `${menuItem.title[SELECTED_LANGUAGE]}: ${reportTab.title[SELECTED_LANGUAGE]}`,
        reportSlug: reportTab.slug,
        themeSlug: menuItem.slug,
      })
    );
  });
  return reportTabsWithIcon;
};
