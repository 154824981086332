import { TypeToast, useToast } from 'admin-portal-shared-services';
import { format } from 'date-fns';
import { CSVDownload } from 'Domain/NewMenuItem';
import useCSVEvents from 'Hooks/segment/useCSVEvents/useCSVEvents';
import { useReports } from 'Hooks/useReports/useReports';
import { useIntl } from 'react-intl';
import { IDataRequest, IRequestBuildCSV } from 'Services/BuildCSV/buildCSV.d';
import buildCSV from 'Services/BuildCSV/buildCSVService';
import getCSV from 'Services/GetCSV/getCSVService';
import { IRequestGetCSV } from 'Services/GetCSV/getCSVService.types';
import { common } from 'Utils/common';

interface UseCSVDownloadParams {
  setIsLoading: (isLoading: boolean) => void;
  setError: (error: string) => void;
  onSuccess: () => void;
  indexCSVDownload: number;
  csvDownload: CSVDownload[];
  vendorId?: string;
  country?: string;
}

interface UseCSVDownloadReturn {
  downloadCSV: (period?: { year: number; month: number }) => void;
}

/* istanbul ignore next */
export function useCSVDownload({
  setIsLoading,
  setError,
  onSuccess,
  indexCSVDownload,
  csvDownload,
}: UseCSVDownloadParams): UseCSVDownloadReturn {
  const { formatMessage, locale } = useIntl();

  const { workspaceSelected, reportBySlug } = useReports();

  const { vendorId } = workspaceSelected;
  const selectedCountry = workspaceSelected.country;

  const onGetBuildCSV = (params: IRequestBuildCSV) => buildCSV(params);
  /* istanbul ignore next */
  const onGetCSV = (params: IRequestGetCSV) => getCSV(params);

  const { triggerCSVDownloadSuccessful, triggerCSVDownloadRequested, triggerCsvDownloadFailed } =
    useCSVEvents();
  const toastService = useToast();

  const onGetDownloadCSV = (period?: { year: number; month: number }) => {
    setIsLoading(true);
    setError('');

    triggerCSVDownloadRequested(period);

    toastService.notify({
      type: TypeToast.INFO,
      message: formatMessage({ id: 'EXPORT_FILE.PREPARING_DOWNLOAD' }),
    });

    const startDate = period && new Date(period.year, period.month - 1, 1);

    if (vendorId && selectedCountry) {
      onGetBuildCSV({
        onSuccess: onBuildCSVSuccess,
        onFail: onBuildCSVFail,
        startDate: startDate && format(startDate, 'yyyy-MM-dd'),
        csvExportCode: csvDownload[indexCSVDownload].csvExportCode,
        preferredLanguage: locale,
        country: selectedCountry,
        reportId: reportBySlug?.id,
        vendorId,
      });
    }
  };

  /* istanbul ignore next */
  const onBuildCSVSuccess = ({ processId }: IDataRequest) => {
    if (!processId) {
      setError('has-error');
      triggerCsvDownloadFailed('NO_INFO_AVAILABLE');
      setIsLoading(false);
      return;
    }

    if (selectedCountry) {
      onGetCSV({
        onSuccess: onGetCSVSuccess,
        onFail: onGetCSVFail,
        country: selectedCountry,
        processId,
      });
    }
  };

  /* istanbul ignore next */
  const onBuildCSVFail = () => {
    toastService.notify({
      type: TypeToast.ERROR,
      message: formatMessage({ id: 'EXPORT_FILE.SOMETHING_WRONG' }),
    });
    setIsLoading(false);
    triggerCsvDownloadFailed('SOMETHING_WENT_WRONG');
  };

  const retryInMilliseconds = 2000;

  /* istanbul ignore next */
  const onGetCSVSuccess = async (data: IDataRequest) => {
    if (data && data.status !== 'DONE') {
      await common.sleep(retryInMilliseconds);
      onBuildCSVSuccess(data);
      return;
    }
    triggerCSVDownloadSuccessful();

    const link = document.createElement('a');
    link.href = data.url;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();

    toastService.notify({
      type: TypeToast.SUCCESS,
      message: formatMessage({ id: 'EXPORT_FILE.SUCCESS_DOWNLOAD' }),
    });

    setIsLoading(false);

    if (onSuccess) {
      onSuccess();
    }
  };

  /* istanbul ignore next */
  const onGetCSVFail = () => {
    setIsLoading(false);

    toastService.notify({
      type: TypeToast.ERROR,
      message: formatMessage({ id: 'EXPORT_FILE.SOMETHING_WRONG' }),
    });

    triggerCsvDownloadFailed('SOMETHING_WENT_WRONG');
  };

  return {
    downloadCSV: onGetDownloadCSV,
  };
}
