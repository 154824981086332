import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  display: 'inline-flex !important',
  alignSelf: 'stretch',
  gap: '8px',
  alignItems: 'center',
});

export const TierLogo = styled('div', {
  width: '16px',
  height: '16px',
  marginLeft: '8px',
});
