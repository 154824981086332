import { css, styled } from '@hexa-ui/theme';

export const ReportContainer = styled('div', {
  height: '100%',
  marginBottom: '2rem',
});

export const reportPowerBI = (isLoading: boolean): ReturnType<typeof css> =>
  css({
    height: isLoading ? '0' : '100%',

    '& iframe': {
      opacity: isLoading ? '0' : '100',
      border: 'none',
      transition: 'opacity 400ms 400ms',
    },
  });
