import React from 'react';
import defaultFlag from '../../assets/flags/default.svg';
import { CountryFlagImg } from './CountryFlag.styles';
import { flags } from './CountryFlagSrcMap';

interface Props {
  country: string;
}

function CountryFlag({ country }: Props): React.ReactElement {
  return (
    <div key={country}>
      <CountryFlagImg
        data-testid={`country-flag-${country}`}
        src={flags[country] || defaultFlag}
        alt={`${country} flag`}
      />
    </div>
  );
}

export default CountryFlag;
