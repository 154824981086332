import { EU_CLUSTER_COUNTRIES } from 'Assets/Constants';
import APIEndpoint from './APIEndpoint';
import getServiceByEndpoint from './getServiceByEndpoint';

const EU_CLUSTER = 'Global_EU';
// eslint-disable-next-line max-params
const getAPIBaseURL = (endpoint: APIEndpoint, country?: string, cluster?: string): string => {
  let isEurope = false;

  if (country) {
    isEurope = EU_CLUSTER_COUNTRIES.includes(country);
  }

  if (cluster) {
    isEurope = EU_CLUSTER.includes(cluster);
  }

  const apiService = getServiceByEndpoint(endpoint);
  if (!apiService) {
    return '';
  }
  const prefix = {
    global: '/api',
    europe: '/api/eu',
  };

  const servicePaths = {
    BFF: '/partner-analytics-business-service',
    partnerRequestService: '/v1/partner-request-service',
  };

  const apiPrefix = isEurope ? `${prefix.europe}` : `${prefix.global}`;

  return `${apiPrefix}${servicePaths[apiService]}`;
};

export default getAPIBaseURL;
