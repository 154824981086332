import MenuItem from 'Domain/MenuItem';
import ReportTab from 'Domain/ReportTab';
import { ReactNode } from 'react';

export enum ReportsPageStatus {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  INVALID_TAB = 'INVALID_TAB',
  INVALID_MENU_ITEM = 'INVALID_MENU_ITEM',
  VENDOR_NOT_SELECTED = 'VENDOR_NOT_SELECTED',
  LOADED = 'LOADED',
}

export type UseReportsPageResult =
  | {
      status:
        | ReportsPageStatus.ERROR
        | ReportsPageStatus.LOADING
        | ReportsPageStatus.VENDOR_NOT_SELECTED;
      menuItems: undefined;
      defaultMenuItem: undefined;
      defaultTab: undefined;
      selectedMenuItem: undefined;
      selectedTab: undefined;
      title: ReactNode;
    }
  | {
      status: ReportsPageStatus.INVALID_MENU_ITEM;
      menuItems: MenuItem[];
      defaultMenuItem: MenuItem;
      defaultTab: ReportTab;
      selectedMenuItem: undefined;
      selectedTab: undefined;
      title: ReactNode;
    }
  | {
      status: ReportsPageStatus.INVALID_TAB;
      menuItems: MenuItem[];
      defaultMenuItem: MenuItem;
      defaultTab: ReportTab;
      selectedMenuItem: MenuItem;
      selectedTab: undefined;
      title: ReactNode;
    }
  | {
      status: ReportsPageStatus.LOADED;
      menuItems: MenuItem[];
      defaultMenuItem: MenuItem;
      defaultTab: ReportTab;
      selectedMenuItem: MenuItem;
      selectedTab: ReportTab;
      title: ReactNode;
    };
