/* eslint-disable react/display-name */
import { Alert, Heading, Paragraph, Select } from '@hexa-ui/components';
import AppModal from 'Components/AppModal/AppModal';
import CsvFileGuideLink from 'Components/CsvFileGuideLink/CsvFileGuideLink';
import useCSVEvents from 'Hooks/segment/useCSVEvents/useCSVEvents';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  AlertMessage,
  ContainerSelect,
  SelectInput,
  SelectMonth,
  SelectPeriodLabel,
  SelectYear,
  Space,
} from './Last12MonthsCSVModal.style';
import { Last12MonthsCSVModalProps } from './Last12MonthsCSVModal.types';

const monthsInYear = 12;

function Last12MonthsCSVModal({
  isOpen,
  onClose,
  onConfirm,
  error,
  isLoading,
  setError,
}: Last12MonthsCSVModalProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { triggerCsvDownloadCanceled } = useCSVEvents();
  const currentYear = String(new Date().getUTCFullYear());
  const currentMonth = format(new Date(), 'M');

  const availableYears = useMemo(() => {
    const previousYear = String(Number(currentYear) - 1);

    return [previousYear, currentYear];
  }, [currentYear]);

  const [selectedYear, setSelectedYear] = useState<string>(currentYear);
  const [touchedConfirm, setTouchedConfirm] = useState<boolean>(false);

  const availableMonthsByYear: Record<string, { id: string; label: string }[]> = useMemo(() => {
    const getMonths = (year: string) => {
      const months = [];

      if (year === currentYear) {
        for (let i = 1; i <= parseInt(currentMonth); i++) {
          months.push({ id: String(i), label: formatMessage({ id: `MONTH.${i}` }) });
        }
      } else {
        for (let i = parseInt(currentMonth); i <= monthsInYear; i++) {
          months.push({ id: String(i), label: formatMessage({ id: `MONTH.${i}` }) });
        }
      }

      return months;
    };

    return availableYears.reduce(
      (result, year) => ({
        ...result,
        [year]: getMonths(year),
      }),
      {}
    );
  }, [availableYears, currentMonth, currentYear, formatMessage]);

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  /* istanbul ignore next */
  const handleChangeYear = (year: string) => {
    setError('');

    const firstMonthAvailable = availableMonthsByYear[year][0].id;
    setSelectedMonth(year === currentYear ? currentMonth : firstMonthAvailable);

    setSelectedYear(year);
  };

  /* istanbul ignore next */
  const handleChangeMonth = (month: string) => {
    setError('');
    setSelectedMonth(month);
  };

  const handleCloseModal = () => {
    /* istanbul ignore next */
    if (isLoading) {
      return;
    }

    // eslint-disable-next-line no-unused-expressions
    !touchedConfirm && triggerCsvDownloadCanceled();

    setError('');

    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear);
    setTouchedConfirm(false);
    onClose();
  };

  const handleConfirm = () => {
    onConfirm({
      year: Number(selectedYear),
      month: Number(selectedMonth),
    });
    setTouchedConfirm(true);
  };

  const renderOptions = (options: string[]) =>
    options.map((value) => (
      <Select.Option data-testid={`select-item-${value}`} key={value} value={value}>
        {value}
      </Select.Option>
    ));

  const renderOptionsMonth = (options: { label: string; id: string }[]) =>
    options.map(({ label, id }) => (
      <Select.Option data-testid={`select-item-${label}`} key={label} value={id}>
        {label}
      </Select.Option>
    ));

  return (
    <AppModal
      isOpen={isOpen}
      onCancelButton={handleCloseModal}
      onConfirmButton={handleConfirm}
      onClose={handleCloseModal}
      buttonConfirmLabel="SELECT_RANGE_DATE_CSV_MODAL.DOWNLOAD"
      isLoading={isLoading}
      title="SELECT_RANGE_DATE_CSV_MODAL.TITLE"
      dataTestId="gold-csv-modal"
    >
      <>
        <SelectPeriodLabel>
          <Paragraph size="basis" className="modal-description">
            {formatMessage({ id: 'SELECT_RANGE_DATE_CSV_MODAL.DESCRIPTION' })}
          </Paragraph>
        </SelectPeriodLabel>
        {error && (
          <AlertMessage>
            <Alert
              data-testid="error-alert"
              type="error"
              message={formatMessage({ id: 'SELECT_RANGE_DATE_CSV_MODAL.NO_INFO_AVAILABLE' })}
              className="alert-message"
            />
          </AlertMessage>
        )}
        {!error && <Space />}
        <ContainerSelect>
          <SelectYear>
            <Heading size="H5">
              {formatMessage({ id: 'SELECT_RANGE_DATE_CSV_MODAL.YEAR_LABEL' })}
            </Heading>
            <SelectInput>
              <Select.Root
                data-testid="select-year"
                value={selectedYear}
                onChange={handleChangeYear}
              >
                {renderOptions(availableYears)}
              </Select.Root>
            </SelectInput>
          </SelectYear>
          <SelectMonth>
            <Heading size="H5">
              {formatMessage({ id: 'SELECT_RANGE_DATE_CSV_MODAL.MONTH_LABEL' })}
            </Heading>
            <SelectInput>
              <Select.Root
                data-testid="select-month"
                value={selectedMonth}
                onChange={handleChangeMonth}
              >
                {renderOptionsMonth(availableMonthsByYear[selectedYear])}
              </Select.Root>
            </SelectInput>
          </SelectMonth>
        </ContainerSelect>
        <CsvFileGuideLink />
      </>
    </AppModal>
  );
}

export default Last12MonthsCSVModal;
