import { css, styled } from '@hexa-ui/theme';

const Container = styled('div', {
  height: '100%',
  marginBottom: '12px',
});

export const reportPowerBI = (isLoading: boolean): ReturnType<typeof css> =>
  css({
    height: isLoading ? '0' : '100%',

    '& iframe': {
      height: '100%',
      opacity: isLoading ? '0' : '100',
      border: 'none',
      transition: 'opacity 400ms 400ms',
    },
  });

export const Styled = {
  Container,
  reportPowerBI,
};
