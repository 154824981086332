import { Heading, Paragraph } from '@hexa-ui/components';
import { CSS } from '@stitches/react/types/css-util';
import { Container } from 'Components/EmptyState/EmptyState.style';
import React from 'react';

export function EmptyState({
  title,
  text,
  css,
}: {
  title?: string;
  text: string;
  css?: CSS;
}): React.ReactElement {
  return (
    <Container css={css} data-testid="empty-state">
      {title && (
        <Heading size="H3" css={{ marginBottom: '$2' }}>
          {title}
        </Heading>
      )}
      <Paragraph size="basis">{text}</Paragraph>
    </Container>
  );
}
