import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import EmbedReport from 'Domain/EmbedReport';

interface GetEmbedReportServiceParams {
  reportCode: string;
  lang: string;
  vendorId: string;
  country: string;
  globalOverview?: boolean;
}

const getEmbedReportService = async ({
  reportCode,
  lang,
  vendorId,
  country,
  globalOverview,
}: GetEmbedReportServiceParams): Promise<EmbedReport> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_EMBED_REPORT, country);

  const response = await Api.get({
    url: '/embed-report',
    params: {
      reportCode,
      vendorId,
      lang,
      globalOverview: globalOverview ? 'true' : undefined,
    },
    headers: {
      country,
    },
    baseURL,
  });

  return response.data as EmbedReport;
};

export default getEmbedReportService;
