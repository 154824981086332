/* eslint-disable @typescript-eslint/no-explicit-any */
export interface HideSpacesParams {
  isMobile: boolean;
  isTablet: boolean;
  isDesktopSmall: boolean;
  data: any[];
  hasBtGuide: boolean;
}

export const hideSpaces = ({
  isMobile,
  isTablet,
  isDesktopSmall,
  data,
  hasBtGuide,
}: HideSpacesParams): boolean => {
  if (isMobile && data.length === 1) return true;
  if (!isMobile && isTablet && data.length <= 2) return true;
  if (!isTablet && isDesktopSmall && data.length <= 3) return true;
  if (!isDesktopSmall && data.length <= (hasBtGuide ? 3 : 4)) return true;

  return false;
};
