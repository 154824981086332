import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import { MenuItem, ReportTab } from 'Domain/NewMenuItem';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useGetReportsByWorkspace } from 'Services/GetReportsByWorkspace/useGetReportsByWorkspace';
import { formatMenuItems, getReportsByTheme } from 'Utils/formatMenuItems/formatMenuItems';
import { ReportsPageStatus } from './useReports.types';
import { getBusinessModelValue } from './utils';

interface RouteParams {
  themeSlug: string;
  reportSlug?: string;
}

interface EmbedReportsReturnType {
  themeSlug: string;
  reportSlug?: string;
  themeSelected?: MenuItem;
  sortedMenuItems: MenuItem[];
  reportsByTheme?: ReportTab[];
  reportBySlug?: ReportTab;
  workspaceSelected: {
    workspaceId?: string;
    segmentId?: string;
    vendorId?: string;
    country?: string;
    vendorName?: string;
    businessModel?: string;
  };
  status: ReportsPageStatus;
  hasReportPermission: boolean;
  isLoadingReports: boolean;
  hasError: boolean;
}

export function useReports(): EmbedReportsReturnType {
  const { themeSlug, reportSlug } = useParams<RouteParams>();
  const { workspaceData, setThemeSelected } = useWorkspaceContext();

  const workspaceSelected = {
    workspaceId: workspaceData?.workspace?.id,
    segmentId: workspaceData?.segment?.id,
    vendorId: workspaceData?.vendorId,
    country: workspaceData?.segment?.country,
    vendorName: workspaceData?.vendorName,
    businessModel: getBusinessModelValue(workspaceData),
  };

  const { locale: SELECTED_LANGUAGE } = useIntl();

  const {
    data: allReportsMenuItems,
    isLoading: isLoadingReports,
    isError: hasReportsErrors,
    isFetched,
  } = useGetReportsByWorkspace(
    workspaceData?.workspace?.id,
    workspaceData?.segment?.id,
    workspaceData?.segment?.country
  );

  const sortedMenuItems = formatMenuItems(allReportsMenuItems?.menuItems || []);
  const themeSelected = getReportsByTheme(themeSlug, sortedMenuItems);
  const reportsByTheme = themeSelected?.reportTabs;
  const reportBySlug = themeSelected?.reportTabs.find((report) => report.slug === reportSlug);

  const hasReportPermission =
    reportBySlug !== undefined && !isLoadingReports && !hasReportsErrors && isFetched;

  const hasWorkspaceError = !workspaceData?.workspace?.id || !workspaceData?.segment?.id;

  const hasError = hasWorkspaceError && hasReportsErrors;

  const getStatus = (): ReportsPageStatus => {
    const hasNoReportTabs =
      !!allReportsMenuItems &&
      (allReportsMenuItems?.menuItems.length < 1 ||
        allReportsMenuItems?.menuItems[0].reportTabs.length < 1);

    const isError = hasReportsErrors || hasNoReportTabs;

    if (isError) {
      return ReportsPageStatus.ERROR;
    }

    if (isLoadingReports) {
      return ReportsPageStatus.LOADING;
    }

    return ReportsPageStatus.LOADED;
  };

  if (themeSelected) {
    setThemeSelected(themeSelected.title[SELECTED_LANGUAGE]);
  }

  const status = getStatus();

  return {
    themeSlug,
    reportSlug,
    themeSelected,
    sortedMenuItems,
    reportsByTheme,
    reportBySlug,
    workspaceSelected,
    status,
    hasReportPermission,
    isLoadingReports,
    hasError,
  };
}
