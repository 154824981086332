import { UserType } from 'Assets/Constants';
import { UserInfoToken } from 'admin-portal-shared-services/dist/services/authentication/AuthenticationService.types';
import { Vendor } from 'admin-portal-shared-services/dist/services/userMetadata/model/UserMetadata';
import { createEvent } from 'effector';

export const setJWT = createEvent<UserInfoToken | null>('setJWT');
export const setLoggedUserType = createEvent<UserType>('setloggedUserType');
export const setVendors = createEvent<Vendor[] | []>('setVendors');
export const setSelectedVendor = createEvent<{
    id: string;
    country: string;
}>('setSelectedVendor');
