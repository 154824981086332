import BRF from 'Assets/logos/brf.svg';
import Gloria from 'Assets/logos/gloria.svg';
import GloriaCo from 'Assets/logos/gloriaco.svg';
import GPA from 'Assets/logos/gpa.svg';
import Mercasid from 'Assets/logos/mercasid.svg';
import Nestle from 'Assets/logos/nestle.svg';
import PepsiCo from 'Assets/logos/pepsico.svg';
import PilAndinaBO from 'Assets/logos/pilandina.svg';

const PartnerLogoSrcMap = new Map<string, string>([
  ['nestle', Nestle],
  ['gpa', GPA],
  ['gloria', Gloria],
  ['mercasid', Mercasid],
  ['brf', BRF],
  ['pepsico', PepsiCo],
  ['gloriaco', GloriaCo],
  ['pilandinabo', PilAndinaBO],
]);

export default PartnerLogoSrcMap;
