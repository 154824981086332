import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  marginBottom: '$4',
  '@media(max-width: 600px)': {
    paddingBottom: '40px',
  },
});

export const Styled = {
  Container,
};
