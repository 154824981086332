import { reportPowerBI } from 'Components/EmbedReport/PowerBIReport/PowerBIReport.style';
import GenericErrorView from 'Components/GenericError/GenericErrorView';
import Loading from 'Components/Loading/Loading';
import useRenderEvents from 'Hooks/segment/useRenderEvents/useRenderEvents';
import useReportsEvents from 'Hooks/segment/useReportsEvents/useReportsEvents';
import { useReports } from 'Hooks/useReports/useReports';
import { ReportsPageStatus } from 'Hooks/useReports/useReports.types';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useGetEmbedReport } from 'Services/GetEmbedReport/useGetEmbedReport';
import { Styled } from './EmbedContent.style';
import { usePowerBi } from './usePowerBi';
import { PowerBIReportEvent, PowerBIReportStatus } from './usePowerBi.types';

type EmbedContentProps = {
  workspaceId: string;
  reportId: string;
  permissionId: string;
  vendorId?: string;
  country: string;
};

export function EmbedContent({
  workspaceId,
  permissionId,
  reportId,
  vendorId,
  country,
}: Readonly<EmbedContentProps>): React.ReactElement {
  const { triggerPartnerReportsViewed } = useReportsEvents();
  const { triggerInitRenderingProcess } = useRenderEvents();

  const { formatMessage, locale: SELECTED_LANGUAGE } = useIntl();

  const { status: EmbedStatus } = useReports();

  const { eventHandlers, status, isLoading, trackReportEvent, setStatus } = usePowerBi();

  const lang = SELECTED_LANGUAGE.split('-')[0].toUpperCase();

  const { data, isError } = useGetEmbedReport({
    lang,
    workspaceId,
    reportId,
    permissionId,
    vendorId,
    country,
  });

  useEffect(() => {
    if (data) {
      triggerPartnerReportsViewed();

      /* istanbul ignore else */
      if (EmbedStatus === ReportsPageStatus.LOADED) {
        triggerInitRenderingProcess();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, EmbedStatus]);

  /* istanbul ignore next */
  useEffect(() => {
    if (reportId) {
      setStatus(PowerBIReportStatus.LOADING_POWERBI);
      trackReportEvent(PowerBIReportEvent.INIT);
    } else {
      setStatus(PowerBIReportStatus.LOADING_EMBED_REPORT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  if (isError) {
    return <GenericErrorView />;
  }

  return (
    <Styled.Container key={data?.embedUrl}>
      {isLoading && (
        <Loading
          title={formatMessage({ id: `EMBED_REPORT.${status}.TITLE` })}
          body={formatMessage({ id: `EMBED_REPORT.${status}.BODY` })}
          container
        />
      )}

      <PowerBIEmbed
        cssClassName={reportPowerBI(isLoading)()}
        eventHandlers={eventHandlers}
        embedConfig={{
          embedUrl: data?.embedUrl,
          type: 'report',
          id: data?.reportId,
          accessToken: data?.embedToken,
          tokenType: models.TokenType.Embed,
          permissions: models.Permissions.Read,
          viewMode: models.ViewMode.View,
          settings: {
            background: models.BackgroundType.Transparent,
            localeSettings: {
              language: lang,
            },
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToWidth,
            },
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            visualRenderedEvents: false,
            bookmarksPaneEnabled: false,
          },
        }}
      />
    </Styled.Container>
  );
}
