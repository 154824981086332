import { useEffect, useState } from 'react';

export const breakpoints: { [key: string]: string } = {
  mobile: '(max-width: 600px)',
  tablet: '(min-width: 601px) and (max-width: 950px)',
  desktopSmall: '(min-width: 951px) and (max-width: 1200px)',
  desktopLarge: '(min-width: 1201px)',
};

const orderedBreakpoints = ['mobile', 'tablet', 'desktopSmall', 'desktopLarge'];

export const useScreenSize = (queries: string[]): boolean[] => {
  const getMatches = (): boolean[] =>
    orderedBreakpoints.map((breakpoint) => {
      const mediaQuery = breakpoints[breakpoint];
      return window.matchMedia(mediaQuery).matches;
    });

  const [matches, setMatches] = useState<boolean[]>(getMatches());

  useEffect(() => {
    const handleResize = () => {
      const newMatches = getMatches();
      setMatches((prevMatches) => {
        /* istanbul ignore if */
        if (newMatches.some((match, index) => match !== prevMatches[index])) {
          return newMatches;
        }
        return prevMatches;
      });
    };

    const mediaQueryLists = orderedBreakpoints.map((breakpoint) =>
      window.matchMedia(breakpoints[breakpoint])
    );

    mediaQueryLists.forEach((mql) => mql.addEventListener('change', handleResize));

    handleResize();

    return () => {
      mediaQueryLists.forEach((mql) => mql.removeEventListener('change', handleResize));
    };
  }, []);

  const hierarchicalMatches = matches.slice();
  for (let i = 0; i < matches.length; i++) {
    if (matches[i]) {
      for (let j = i + 1; j < matches.length; j++) {
        hierarchicalMatches[j] = true;
      }
      break;
    }
  }

  return queries.map((query) => {
    const index = orderedBreakpoints.indexOf(query);
    return hierarchicalMatches[index];
  });
};
