import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: '400px',
  textAlign: 'center',
  width: '100%',

  svg: {
    marginBottom: '$6',
  },

  p: {
    fontSize: '$5',
    lineHeight: '1.75rem',
    textAlign: 'center',
  },
});
