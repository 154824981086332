import { Paragraph, Select } from '@hexa-ui/components';
import PartnerLogo from 'Components/PartnerLogo/PartnerLogo';
import React from 'react';
import { IconContainer } from './MultiVendorSelect.style';

interface MultiVendorSelectProps {
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
  children?: React.ReactNode;
  dataTestId: string;
  vendor: {
    displayName: string;
    id: string;
  };
  defaultValue: string;
}

function MultiVendorSelect({
  value,
  disabled,
  onChange,
  children,
  dataTestId,
  vendor,
  defaultValue,
}: MultiVendorSelectProps): React.ReactElement {
  const placeholderIcon = (
    <IconContainer>
      <PartnerLogo vendorId={vendor.id} />
      <Paragraph weight="semibold" style={{ marginTop: '8px', marginRight: '32px' }}>
        {vendor.displayName}
      </Paragraph>
    </IconContainer>
  );

  return (
    <Select.Root
      data-testid={dataTestId}
      onChange={onChange}
      disabled={disabled}
      value={value}
      shape="square"
      icon={placeholderIcon}
      defaultValue={defaultValue}
    >
      {children}
    </Select.Root>
  );
}

export default MultiVendorSelect;
