import { UserType } from 'Assets/Constants';
import { createStore } from 'effector';
import { setJWT, setLoggedUserType, setSelectedVendor, setVendors } from './LoggedUserEvents';
import { ILoggedUserState } from './LoggedUserState';

export const loggedUserStoreInitialState: ILoggedUserState = {
    jwtDecoded: null,
    loggedUserType: UserType.LOADING,
    vendors: [],
    selectedVendor: {
        id: '',
        country: '',
    },
};

const LoggedUserStore = createStore(loggedUserStoreInitialState)
    .on(setJWT, (state, payload) => ({
        ...state,
        jwtDecoded: payload,
    }))
    .on(setLoggedUserType, (state, payload) => ({
        ...state,
        loggedUserType: payload,
    }))
    .on(setVendors, (state, payload) => ({
        ...state,
        vendors: payload,
    }))
    .on(setSelectedVendor, (state, payload) => ({
        ...state,
        selectedVendor: payload,
    }));

export default LoggedUserStore;
