import ReportTab, { ReportTabResponse } from './ReportTab';

export interface MenuItemResponse {
  iconString: string;
  id: string;
  title: Record<string, string>;
  slug: string;
  trackingId: string;
  reportTabs: ReportTabResponse[];
  viewMode: 'TAB' | 'VIEW';
  order: number;
}

export default class MenuItem {
  public readonly title: Record<string, string>;

  public readonly slug: string;

  public readonly trackingId: string;

  public readonly viewMode: 'TAB' | 'VIEW';

  public readonly id: string;

  public readonly reportTabs: ReportTab[];

  public readonly order: number;

  public readonly iconString: string;

  constructor({
    id,
    title,
    slug,
    trackingId,
    reportTabs,
    viewMode,
    order,
    iconString,
  }: MenuItemResponse) {
    this.id = id;
    this.title = title;
    this.slug = slug;
    this.trackingId = trackingId;
    this.viewMode = viewMode;
    this.reportTabs = reportTabs.map((tab) => new ReportTab(tab));
    this.order = order;
    this.iconString = iconString;
  }

  getTitle(locale: string, defaultLocale: string): string {
    return this.title[locale] || this.title[defaultLocale];
  }
}

export const getMenuItemExample = (menuItem: Partial<MenuItemResponse> = {}): MenuItem =>
  new MenuItem({
    iconString: 'BarChart',
    id: 'BUSINESS',
    title: {
      'en-US': 'Business',
      'es-419': 'Negocio',
      'pt-BR': 'Negócio',
      'id-ID': 'Bisnis',
    },
    slug: 'business',
    trackingId: 'BUSINESS',
    order: 1,
    reportTabs: [
      {
        reportCode: 'ORDERS',
        slug: 'orders',
        trackingId: 'ORDERS',
        csvDownload: [],
        title: {
          'en-US': 'Orders',
          'pt-BR': 'Pedidos',
          'es-419': 'Pedidos',
          'id-ID': 'Pesanan',
        },
        viewMode: 'TAB',
      },
      {
        reportCode: 'REVENUE',
        slug: 'revenue',
        trackingId: 'REVENUE',
        csvDownload: [],
        title: {
          'en-US': 'Revenue',
          'pt-BR': 'Faturamento',
          'es-419': 'Facturación',
          'id-ID': 'Pendapatan',
        },
        viewMode: 'TAB',
      },
    ],
    viewMode: 'VIEW',
    ...menuItem,
  });

const globalOverviewSlug = 'global-overview';

export const getGlobalOverviewMenuItem = (): MenuItem[] => [
  new MenuItem({
    iconString: 'BarChart',
    slug: globalOverviewSlug,
    id: globalOverviewSlug,
    trackingId: 'GLOBAL_OVERVIEW',
    order: 1,
    reportTabs: [
      {
        slug: globalOverviewSlug,
        trackingId: globalOverviewSlug,
        reportCode: 'GLOBAL_OVERVIEW',
        csvDownload: [],
        title: {
          'pt-BR': 'Visão geral global',
          'en-US': 'Global overview',
          'es-419': 'Descripción general global',
          'id-ID': 'Ikhtisar global',
        },
        viewMode: 'TAB',
      },
    ],
    title: {
      'pt-BR': 'Visão Geral Global',
      'en-US': 'Global Overview',
      'es-419': 'Descripción General Global',
      'id-ID': 'Ikhtisar Global',
    },
    viewMode: 'VIEW',
  }),
];
