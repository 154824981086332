import CSVGoldButton from 'Components/CSVDownloadButton/CSVGoldButton/CSVGoldButton';
import React from 'react';

interface CSVDownloadButtonProps {
  onClick: (index: number) => void;
}

/* istanbul ignore next */
export function CSVDownloadButton({ onClick }: CSVDownloadButtonProps): React.ReactElement {
  const handleSingleCSVDownloadClick = () => {
    onClick(0);
  };

  return <CSVGoldButton onClick={handleSingleCSVDownloadClick} />;
}
