import { Card, Heading } from '@hexa-ui/components';
import { PermissionRuleType } from 'Domain/Workspaces';
import useAppEvents from 'Hooks/segment/useAppEvents/useAppEvents';
import React from 'react';
import { useIntl } from 'react-intl';
import Challenge from '../../assets/images/challenge.svg';
import { Styled } from './BTGuideSection.style';

type BTGuideSectionProps = {
  btGuideUrl: string;
  selectedWorkspace: any;
};

function BTGuideSection({
  btGuideUrl,
  selectedWorkspace,
}: Readonly<BTGuideSectionProps>): React.ReactElement {
  const { formatMessage } = useIntl();
  const { triggerLinkOutButtonClicked } = useAppEvents();
  const BT_GUIDE_LINK = formatMessage({ id: 'MAIN_PAGE.BTGUIDE_SECTION.LINK' });

  const triggerEventAndRedirect = () => {
    const businessModel =
      selectedWorkspace?.segment.rule.type === PermissionRuleType.BUSINESS_MODEL
        ? selectedWorkspace?.segment.rule.value
        : null;

    triggerLinkOutButtonClicked({
      button_name: BT_GUIDE_LINK,
      button_label: BT_GUIDE_LINK,
      country: selectedWorkspace?.country,
      screen_name: 'MAIN_PAGE',
      vendor_id: selectedWorkspace.vendorId,
      vendor_name: selectedWorkspace?.vendorName,
      business_model: businessModel,
    });

    /* istanbul ignore next */
    setTimeout(() => {
      window.open(btGuideUrl, '_blank');
    }, 100);
  };

  return (
    <Card border="medium" elevated="small" css={{ padding: '24px', gap: '4px' }}>
      <Heading size="H3">{formatMessage({ id: 'MAIN_PAGE.BTGUIDE_SECTION.TITLE' })}</Heading>
      <Styled.LinkContainer onClick={triggerEventAndRedirect} data-testid="bt_guide_redirect">
        {BT_GUIDE_LINK}
      </Styled.LinkContainer>
      <Card
        border="medium"
        elevated="small"
        css={{
          padding: '24px',
          boxShadow: 'none',
          backgroundColor: '#FAFAFA',
          border: 'solid 1px rgba(20, 20, 20, 0.12)',
        }}
      >
        <Styled.ImageAndTextWrapper>
          <img src={Challenge} alt="challenge" />
          <Heading size="H4">
            {formatMessage({ id: 'MAIN_PAGE.BTGUIDE_SECTION.DESCRIPTION' })}
          </Heading>
        </Styled.ImageAndTextWrapper>
      </Card>
    </Card>
  );
}

export default BTGuideSection;
