import CountryMultipleVendorsSelect from 'Components/CountryMultipleVendorsSelect/CountryMultipleVendorsSelect';
import PageContainer from 'Components/PageContainer/PageContainer';
import Sidebar from 'Components/Sidebar/Sidebar';
import { isGlobalPartner, isMultiVendor } from 'Domain/User';
import useReportsPage from 'Hooks/useReportsPage/useReportsPage';
import { HeadingContainer, PageTitle } from 'Pages/ReportsPage/ReportsPage.style';
import { SelectedVendor } from 'Pages/ReportsPage/components/SelectedVendor/SelectedVendor';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import React from 'react';
import { MultiVendorSelectWrapper, TabsLoader } from './ReportsPageWrapper.style';

function ReportsPageWrapper({
  children,
  isLoading = false,
}: {
  children?: React.ReactNode;
  isLoading?: boolean;
}): React.ReactElement {
  const { title, menuItems } = useReportsPage();
  const { selectedVendor, vendors } = LoggedUserStore.getState();
  const manufacturerIds = vendors.map((vendor) => vendor.id).join(',');

  return (
    <>
      <Sidebar reportsMenuItems={menuItems} />
      <PageContainer maxWidth="xl">
        <HeadingContainer>
          <PageTitle>{title}</PageTitle>
          {isMultiVendor() || isGlobalPartner() ? (
            <MultiVendorSelectWrapper>
              <CountryMultipleVendorsSelect
                selectedValue={selectedVendor.id}
                manufacturerIds={manufacturerIds}
              />
            </MultiVendorSelectWrapper>
          ) : (
            <SelectedVendor />
          )}
        </HeadingContainer>

        {isLoading && <TabsLoader />}

        {children}
      </PageContainer>
    </>
  );
}

export default ReportsPageWrapper;
