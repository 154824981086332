export const getInitials = (name: string): string => {
  if (!name) return '';

  const cleanedName = name.replace(/[^a-zA-Z\s-]/g, '').trim();

  if (cleanedName.length === 2) {
    return cleanedName.toUpperCase();
  }

  const [firstPart] = cleanedName.split(' - ');

  const words = firstPart.split(/\s+/);

  if (words.length >= 2) {
    return words
      .slice(0, 2)
      .map((word) => word[0])
      .join('')
      .toUpperCase();
  }
  return words[0].slice(0, 2).toUpperCase();
};
