import { Card, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const CardContainer = styled(Card, {
  display: 'flex',
  flexDirection: 'row !important',
  padding: '18px 16px',
  overflow: 'hidden',
  flexWrap: 'nowrap',
  gap: '16px',
  marginLeft: 'auto',
  alignItems: 'center',
  variants: {
    isTablet: {
      true: {
        padding: '8px',
        gap: '8px',
      },
    },
  },
});

const Item = styled(Paragraph, {
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: '22px',
});

const Divider = styled('div', {
  width: '1px',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  alignSelf: 'stretch',
});

export const Styled = {
  CardContainer,
  Divider,
  Item,
};
