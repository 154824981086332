import { Download } from '@hexa-ui/icons';
import { Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';
import CSVDownload from 'Domain/CSVDownload';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  StyledButton,
  StyledChevronDown,
  StyledDropdownMenuContent,
  StyledDropdownMenuItem,
  StyledIconContainer,
  StyledValueContainer,
} from './CSVDownloadSelect.style';

interface CSVDownloadSelectProps {
  onSelect: (index: number) => void;
  csvDownload: CSVDownload[];
}

export default function CSVDownloadSelect({
  onSelect,
  csvDownload,
}: CSVDownloadSelectProps): React.ReactElement {
  const { formatMessage, locale, defaultLocale } = useIntl();

  const renderMenuItems = () =>
    csvDownload.map((option: CSVDownload, index) => (
      <StyledDropdownMenuItem
        key={`csv-select-download-${option.csvExportCode}`}
        onClick={() => onSelect(index)}
        data-testid={`csv-select-download-${option.csvExportCode}`}
      >
        {option.getTitle(locale, defaultLocale)}
      </StyledDropdownMenuItem>
    ));

  return (
    <Root>
      <Trigger asChild>
        <StyledButton data-testid="csv-select-download" size="medium" variant="secondary">
          <StyledIconContainer>
            <Download size="medium" />
          </StyledIconContainer>
          <StyledValueContainer>
            {formatMessage({ id: 'CSV_DOWNLOAD_SELECT.PLACEHOLDER' })}
          </StyledValueContainer>
          <StyledChevronDown size="medium" />
        </StyledButton>
      </Trigger>
      <Portal>
        <StyledDropdownMenuContent sideOffset={4} align="end">
          {renderMenuItems()}
        </StyledDropdownMenuContent>
      </Portal>
    </Root>
  );
}
