import { styled } from '@hexa-ui/theme';

export const Image = styled('img', {
  height: '125px',
  width: '100%',
});

export const Content = styled('div', {
  '.errorTitle': {
    marginTop: '16px',
    marginBottom: '8px',
    fontSize: '24px',
  },
  '.errorMessage': {
    fontSize: '16px',
    fontFamily: 'Work Sans',
  },
});
