export enum ReportsPageStatus {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  LOADED = 'LOADED',
  INVALID_THEME = 'INVALID_THEME',
  INVALID_REPORT = 'INVALID_REPORT',
  INVALID = 'INVALID',
  REDIRECT = 'REDIRECT',
  NO_PERMISSION = 'NO_PERMISSION',
}
