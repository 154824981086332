import { styled } from '@hexa-ui/theme';

export const ToggleStyles = styled('div', {
  '.container': {
    paddingBottom: '24px',
    display: 'flex',
    alignItems: 'center',

    '> button': {
      marginLeft: 'auto',
    },

    '& p': {
      fontFamily: 'Work Sans',
      fontSize: '14px',
    },
  },
  '.container:empty': {
    paddingBottom: 0,
  },
});
