import { CSS } from '@stitches/react/types/css-util';
import Loading from 'Components/Loading/Loading';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ReportContainer, reportPowerBI } from './PowerBIReport.style';
import usePowerBIReport from './usePowerBIReport';
import { PowerBIReportEvent, PowerBIReportStatus } from './usePowerBIReport.types';

interface PowerBIReportProps {
  embedUrl?: string;
  reportId?: string;
  embedToken?: string;
  lang: string;
  css?: CSS;
}

export function PowerBIReport({
  embedUrl,
  embedToken,
  reportId,
  lang,
  css,
}: PowerBIReportProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { eventHandlers, status, isLoading, trackReportEvent, setStatus } = usePowerBIReport();

  /* istanbul ignore next */
  useEffect(() => {
    if (reportId) {
      setStatus(PowerBIReportStatus.LOADING_POWERBI);
      trackReportEvent(PowerBIReportEvent.INIT);
    } else {
      setStatus(PowerBIReportStatus.LOADING_EMBED_REPORT);
    }
  }, [reportId]);

  return (
    <ReportContainer data-testid="power-bi-report" css={css}>
      {isLoading && (
        <Loading
          title={formatMessage({ id: `EMBED_REPORT.${status}.TITLE` })}
          body={formatMessage({ id: `EMBED_REPORT.${status}.BODY` })}
          container
        />
      )}
      <PowerBIEmbed
        cssClassName={reportPowerBI(isLoading)()}
        eventHandlers={eventHandlers}
        embedConfig={{
          embedUrl,
          type: 'report',
          id: reportId,
          accessToken: embedToken,
          tokenType: models.TokenType.Embed,
          permissions: models.Permissions.Read,
          viewMode: models.ViewMode.View,
          settings: {
            background: models.BackgroundType.Transparent,
            localeSettings: {
              language: lang,
            },
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToWidth,
            },
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            visualRenderedEvents: false,
            bookmarksPaneEnabled: false,
          },
        }}
      />
    </ReportContainer>
  );
}
