import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthenticationService, useUserMetadata } from 'admin-portal-shared-services';
import { GLOBAL_OVERVIEW, UserType } from 'Assets/Constants';
import { isGlobalPartner, isManager, isManufacturer, isMultiVendor } from 'Domain/User';
import Vendor from 'Domain/Vendor';
import { useCountry } from 'Hooks/useCountry/useCountry';
import { useParams } from 'react-router-dom';
import getReportsMFEConfigService from 'Services/GetReportsMFEConfig/getReportsMFEConfigService';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';

export interface UseSelectedVendorReturn {
  selectedVendor: UseQueryResult<Vendor>;
  vendorId: string | undefined;
  selectedCountry: string;
  vendorName: string | undefined;
}

const useSelectedVendor = (): UseSelectedVendorReturn => {
  const defaultCountry = useCountry();
  const userMetadata = useUserMetadata();
  const vendorsFromMetadata = userMetadata.data?.vendors;
  const mainVendor = vendorsFromMetadata && vendorsFromMetadata[0];
  const jwt = useAuthenticationService().parseJwt();
  const vendorIdFromJWT = jwt?.vendorId;
  const defaultVendorId = mainVendor?.id || vendorIdFromJWT;

  const { vendorId: vendorIdFromURL } = useParams<{
    vendorId?: string;
  }>();

  const { selectedVendor: vendorFromSelect } = LoggedUserStore.getState();

  const getVendorId = (): string | undefined => {
    const { loggedUserType } = LoggedUserStore.getState();
    if (loggedUserType === UserType.UNAUTHORIZED || loggedUserType === UserType.LOADING)
      return undefined;

    if (!isManager() && (isMultiVendor() || isGlobalPartner() || isManufacturer())) {
      if (vendorFromSelect.id === GLOBAL_OVERVIEW) {
        return defaultVendorId;
      }
      return vendorFromSelect?.id;
    }
    /* istanbul ignore next */
    return isManager() ? vendorIdFromURL : defaultVendorId;
  };

  const getCountry = (): string => {
    if (!isManager() && (isMultiVendor() || isGlobalPartner() || isManufacturer())) {
      if (vendorFromSelect.id === GLOBAL_OVERVIEW) {
        return mainVendor?.country || defaultCountry;
      }
      return vendorFromSelect?.country;
    }
    /* istanbul ignore else */
    if (isManager()) {
      return defaultCountry;
    }
    /* istanbul ignore next */
    return mainVendor?.country || defaultCountry;
  };

  const vendorId = getVendorId();
  const selectedCountry = getCountry();
  const selectedVendor = useQuery({
    queryKey: ['reportsMFEConfig', vendorId, selectedCountry],
    queryFn: () =>
      getReportsMFEConfigService({ vendorId: vendorId as string, country: selectedCountry }),
    enabled: !!vendorId,
    select: (data) => data.selectedVendor,
    refetchOnWindowFocus: false,
  });
  const vendorName = selectedVendor?.data?.name;

  return { vendorId, selectedVendor, selectedCountry, vendorName };
};

export default useSelectedVendor;
