import { Workspace } from 'Domain/Workspaces';

export function moveItemToFirstPosition(id: string, allWorkspaces: Workspace[]): Workspace[] {
  const newArr = [...allWorkspaces];
  const index = newArr.findIndex((obj) => obj.id === id);

  if (index !== -1) {
    const [target] = newArr.splice(index, 1);
    newArr.unshift(target);
  }

  return newArr;
}
