import React from 'react';

function BackgroundImage(): React.ReactElement {
  return (
    <svg
      width="842"
      height="335"
      viewBox="0 0 842 335"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        zIndex: -1,
        borderRadius: '24px 24px 0 0',
      }}
    >
      <g clipPath="url(#clip0_1_3)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.87817 -129.775H831.878C837.401 -129.775 841.878 -125.65 841.878 -120.563V340.03H-0.121826V-120.563C-0.121826 -125.65 4.35533 -129.775 9.87817 -129.775Z"
          fill="url(#paint0_linear_1_3)"
        />
        <path
          d="M-0.121826 167.545C36.626 164.977 65.3333 177.641 86 205.537C117 247.382 126 263.572 177.5 278.311C229 293.05 243.447 327.595 252 340.031"
          stroke="#AE6DFF"
          strokeWidth="5"
        />
        <path
          d="M95 136.448C97.7614 136.448 100 134.385 100 131.842C100 129.299 97.7614 127.236 95 127.236C92.2386 127.236 90 129.299 90 131.842C90 134.385 92.2386 136.448 95 136.448Z"
          fill="#D8D8D8"
        />
        <path
          d="M365 136.448C367.761 136.448 370 134.385 370 131.842C370 129.299 367.761 127.236 365 127.236C362.239 127.236 360 129.299 360 131.842C360 134.385 362.239 136.448 365 136.448Z"
          fill="#D8D8D8"
        />
        <path
          d="M185 136.448C187.761 136.448 190 134.385 190 131.842C190 129.299 187.761 127.236 185 127.236C182.239 127.236 180 129.299 180 131.842C180 134.385 182.239 136.448 185 136.448Z"
          fill="#D8D8D8"
        />
        <path
          d="M455 136.448C457.761 136.448 460 134.385 460 131.842C460 129.299 457.761 127.236 455 127.236C452.239 127.236 450 129.299 450 131.842C450 134.385 452.239 136.448 455 136.448Z"
          fill="#D8D8D8"
        />
        <path
          d="M275 136.448C277.761 136.448 280 134.385 280 131.842C280 129.299 277.761 127.236 275 127.236C272.239 127.236 270 129.299 270 131.842C270 134.385 272.239 136.448 275 136.448Z"
          fill="#D8D8D8"
        />
        <path
          d="M545 136.448C547.761 136.448 550 134.385 550 131.842C550 129.299 547.761 127.236 545 127.236C542.239 127.236 540 129.299 540 131.842C540 134.385 542.239 136.448 545 136.448Z"
          fill="#D8D8D8"
        />
        <path
          d="M95 14.8514C97.7614 14.8514 100 12.7889 100 10.2455C100 7.70209 97.7614 5.63956 95 5.63956C92.2386 5.63956 90 7.70209 90 10.2455C90 12.7889 92.2386 14.8514 95 14.8514Z"
          fill="#D8D8D8"
        />
        <path
          d="M365 14.8514C367.761 14.8514 370 12.7889 370 10.2455C370 7.70209 367.761 5.63956 365 5.63956C362.239 5.63956 360 7.70209 360 10.2455C360 12.7889 362.239 14.8514 365 14.8514Z"
          fill="#D8D8D8"
        />
        <path
          d="M185 14.8514C187.761 14.8514 190 12.7889 190 10.2455C190 7.70209 187.761 5.63956 185 5.63956C182.239 5.63956 180 7.70209 180 10.2455C180 12.7889 182.239 14.8514 185 14.8514Z"
          fill="#D8D8D8"
        />
        <path
          d="M455 14.8514C457.761 14.8514 460 12.7889 460 10.2455C460 7.70209 457.761 5.63956 455 5.63956C452.239 5.63956 450 7.70209 450 10.2455C450 12.7889 452.239 14.8514 455 14.8514Z"
          fill="#D8D8D8"
        />
        <path
          d="M275 14.8514C277.761 14.8514 280 12.7889 280 10.2455C280 7.70209 277.761 5.63956 275 5.63956C272.239 5.63956 270 7.70209 270 10.2455C270 12.7889 272.239 14.8514 275 14.8514Z"
          fill="#D8D8D8"
        />
        <path
          d="M545 14.8514C547.761 14.8514 550 12.7889 550 10.2455C550 7.70209 547.761 5.63956 545 5.63956C542.239 5.63956 540 7.70209 540 10.2455C540 12.7889 542.239 14.8514 545 14.8514Z"
          fill="#D8D8D8"
        />
        <path
          d="M95 197.246C97.7614 197.246 100 195.184 100 192.64C100 190.097 97.7614 188.034 95 188.034C92.2386 188.034 90 190.097 90 192.64C90 195.184 92.2386 197.246 95 197.246Z"
          fill="#D8D8D8"
        />
        <path
          d="M365 197.246C367.761 197.246 370 195.184 370 192.64C370 190.097 367.761 188.034 365 188.034C362.239 188.034 360 190.097 360 192.64C360 195.184 362.239 197.246 365 197.246Z"
          fill="#D8D8D8"
        />
        <path
          d="M185 197.246C187.761 197.246 190 195.184 190 192.64C190 190.097 187.761 188.034 185 188.034C182.239 188.034 180 190.097 180 192.64C180 195.184 182.239 197.246 185 197.246Z"
          fill="#D8D8D8"
        />
        <path
          d="M455 197.246C457.761 197.246 460 195.184 460 192.64C460 190.097 457.761 188.034 455 188.034C452.239 188.034 450 190.097 450 192.64C450 195.184 452.239 197.246 455 197.246Z"
          fill="#D8D8D8"
        />
        <path
          d="M275 197.246C277.761 197.246 280 195.184 280 192.64C280 190.097 277.761 188.034 275 188.034C272.239 188.034 270 190.097 270 192.64C270 195.184 272.239 197.246 275 197.246Z"
          fill="#D8D8D8"
        />
        <path
          d="M545 197.246C547.761 197.246 550 195.184 550 192.64C550 190.097 547.761 188.034 545 188.034C542.239 188.034 540 190.097 540 192.64C540 195.184 542.239 197.246 545 197.246Z"
          fill="#D8D8D8"
        />
        <path
          d="M95 75.6497C97.7614 75.6497 100 73.5872 100 71.0438C100 68.5004 97.7614 66.4378 95 66.4378C92.2386 66.4378 90 68.5004 90 71.0438C90 73.5872 92.2386 75.6497 95 75.6497Z"
          fill="#D8D8D8"
        />
        <path
          d="M365 75.6497C367.761 75.6497 370 73.5872 370 71.0438C370 68.5004 367.761 66.4378 365 66.4378C362.239 66.4378 360 68.5004 360 71.0438C360 73.5872 362.239 75.6497 365 75.6497Z"
          fill="#D8D8D8"
        />
        <path
          d="M185 75.6497C187.761 75.6497 190 73.5872 190 71.0438C190 68.5004 187.761 66.4378 185 66.4378C182.239 66.4378 180 68.5004 180 71.0438C180 73.5872 182.239 75.6497 185 75.6497Z"
          fill="#D8D8D8"
        />
        <path
          d="M455 75.6497C457.761 75.6497 460 73.5872 460 71.0438C460 68.5004 457.761 66.4378 455 66.4378C452.239 66.4378 450 68.5004 450 71.0438C450 73.5872 452.239 75.6497 455 75.6497Z"
          fill="#D8D8D8"
        />
        <path
          d="M275 75.6497C277.761 75.6497 280 73.5872 280 71.0438C280 68.5004 277.761 66.4378 275 66.4378C272.239 66.4378 270 68.5004 270 71.0438C270 73.5872 272.239 75.6497 275 75.6497Z"
          fill="#D8D8D8"
        />
        <path
          d="M545 75.6497C547.761 75.6497 550 73.5872 550 71.0438C550 68.5004 547.761 66.4378 545 66.4378C542.239 66.4378 540 68.5004 540 71.0438C540 73.5872 542.239 75.6497 545 75.6497Z"
          fill="#D8D8D8"
        />
        <path
          d="M95 258.044C97.7614 258.044 100 255.982 100 253.439C100 250.895 97.7614 248.833 95 248.833C92.2386 248.833 90 250.895 90 253.439C90 255.982 92.2386 258.044 95 258.044Z"
          fill="#D8D8D8"
        />
        <path
          d="M365 258.044C367.761 258.044 370 255.982 370 253.439C370 250.895 367.761 248.833 365 248.833C362.239 248.833 360 250.895 360 253.439C360 255.982 362.239 258.044 365 258.044Z"
          fill="#D8D8D8"
        />
        <path
          d="M185 258.044C187.761 258.044 190 255.982 190 253.439C190 250.895 187.761 248.833 185 248.833C182.239 248.833 180 250.895 180 253.439C180 255.982 182.239 258.044 185 258.044Z"
          fill="#D8D8D8"
        />
        <path
          d="M455 258.044C457.761 258.044 460 255.982 460 253.439C460 250.895 457.761 248.833 455 248.833C452.239 248.833 450 250.895 450 253.439C450 255.982 452.239 258.044 455 258.044Z"
          fill="#D8D8D8"
        />
        <path
          d="M275 258.044C277.761 258.044 280 255.982 280 253.439C280 250.895 277.761 248.833 275 248.833C272.239 248.833 270 250.895 270 253.439C270 255.982 272.239 258.044 275 258.044Z"
          fill="#D8D8D8"
        />
        <path
          d="M545 258.044C547.761 258.044 550 255.982 550 253.439C550 250.895 547.761 248.833 545 248.833C542.239 248.833 540 250.895 540 253.439C540 255.982 542.239 258.044 545 258.044Z"
          fill="#D8D8D8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M831.878 -129.775C837.401 -129.775 841.878 -125.65 841.878 -120.563L841.879 15.0439C824.466 21.2711 794.496 22.5884 777.686 38.8022C755.904 59.8117 753.208 102.364 732.5 102.364C673.414 102.364 660.99 55.1367 635 10.2455C630.036 1.67198 619.055 -4.34797 608.04 -10.3449L606.504 -11.1813L604.97 -12.0196C591.45 -19.4324 578.725 -27.1667 578 -39.9592C575 -92.9274 628.26 -104.225 645 -126.976C645.696 -127.922 646.379 -128.856 647.05 -129.777L831.878 -129.775Z"
          fill="url(#paint1_linear_1_3)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M711 252.517C717.241 252.517 723.315 253.176 729.145 254.421L651.164 340.03L632.404 340.031C632.173 338.909 631.97 337.779 631.795 336.64L708.388 252.556C709.255 252.53 710.126 252.517 711 252.517ZM742.459 258.434C748.231 260.71 753.654 263.594 758.632 266.997L692.106 340.03H668.133L742.459 258.434ZM768.412 274.891C772.281 278.563 775.739 282.606 778.717 286.955L730.37 340.03H709.076L768.412 274.891ZM785.157 298.514C787.423 303.658 789.077 309.09 790.036 314.735L766.993 340.03H747.34L785.157 298.514ZM790.7 332.636C790.466 335.139 790.095 337.605 789.596 340.031L783.964 340.03L790.7 332.636ZM688.843 255.38L631.445 318.392C634.895 288.257 658.063 263.537 688.843 255.38Z"
          fill="url(#paint2_linear_1_3)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1_3"
          x1="420.878"
          y1="-129.775"
          x2="420.878"
          y2="340.03"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBF7FF" />
          <stop offset="1" stopColor="#E2CFF7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_3"
          x1="655.199"
          y1="23.984"
          x2="829.715"
          y2="-153.986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3CDFF" />
          <stop offset="1" stopColor="#BB88FA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_3"
          x1="711.073"
          y1="280.703"
          x2="734.608"
          y2="335.131"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3C3C3C" />
          <stop offset="1" />
        </linearGradient>
        <clipPath id="clip0_1_3">
          <rect width="842" height="335" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BackgroundImage;
