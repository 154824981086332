import { styled } from '@hexa-ui/theme';

export const CSVSilverButtonContainer = styled('button', {
  border: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: '116px',
  paddingLeft: '8px',
  fontSize: '.85rem',
  height: '40px',
  backgroundColor: '#F0ECFC',
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: 'Work Sans',
  fontWeight: 600,
  borderRadius: '4px',
  flexShrink: '0',
  '.svg-render': {
    alignSelf: 'start',
    marginRight: '2px',
  },
  '&:hover': {
    backgroundColor: '#E5DEFC',
  },
});
