import { styled } from '@hexa-ui/theme';

export const BuzzLoading = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '16px',
});

export const LoadingTitle = styled('div', {
  marginBottom: '8px',
});

export const FullScreen = styled('div', {
  zIndex: 999,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  color: 'white',
  backgroundColor: 'rgba(0,0,0,.75)',

  'h5,p': {
    transform: 'scale(1.1)',
    fontWeight: 'bolder',
    color: '#fff !important',
  },

  svg: {
    width: 100,
    height: 100,
  },
});
