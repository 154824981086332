import { Button, Dialog, Heading } from '@hexa-ui/components';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AppModalProps } from './AppModal.d';
import { Actions } from './AppModal.style';

function AppModal({
  isOpen,
  onClose,
  title,
  isLoading,
  buttonConfirmLabel = 'COMMON.CONFIRM',
  removeButtonConfirm = false,
  onConfirmButton: onConfirm,
  buttonCancelLabel = 'COMMON.CANCEL',
  removeButtonCancel = false,
  disableConfirmButton = false,
  onCancelButton: onCancel,
  children,
  css,
  dataTestId,
  headerImage,
}: AppModalProps): React.ReactElement {
  const { formatMessage } = useIntl();

  const onClickClose = useCallback(() => onClose(false), [onClose]);

  return (
    <Dialog.Root
      modal
      open={isOpen}
      actions={null}
      headerImage={
        headerImage && (
          <div style={{ borderRadius: '24px 24px 0 0', overflow: 'hidden' }}>{headerImage}</div>
        )
      }
      contentCss={css}
      title={
        title && (
          <Heading size="H2" className="title" data-testid="title-id">
            {formatMessage({ id: title })}
          </Heading>
        )
      }
      onClose={onClickClose}
    >
      <div data-testid={dataTestId ?? 'modal'}>
        {children}
        {!(removeButtonCancel && removeButtonConfirm) && (
          <Actions>
            {!removeButtonCancel && (
              <Button
                className="btn-action"
                data-testid="cancel-button"
                onClick={onCancel}
                disabled={isLoading}
                size="large"
                style={{ marginRight: '0.9375rem' }}
                variant="secondary"
              >
                {formatMessage({ id: buttonCancelLabel })}
              </Button>
            )}
            {!removeButtonConfirm && (
              <Button
                className="btn-action"
                data-testid="confirm-button"
                isLoading={isLoading}
                disabled={disableConfirmButton}
                onClick={onConfirm}
                size="large"
                variant="primary"
              >
                {formatMessage({ id: buttonConfirmLabel })}
              </Button>
            )}
          </Actions>
        )}
      </div>
    </Dialog.Root>
  );
}

export default AppModal;
