import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { SUCCESS_CODE } from 'Assets/Constants';
import { IRequestGetCSV } from './getCSVService.types';

const getCSV = async (requestParams: IRequestGetCSV): Promise<string | undefined> => {
  const { onSuccess, onFail, processId, country } = requestParams;

  const baseURL = getAPIBaseURL(APIEndpoint.GET_CSV, country);

  try {
    const response = await Api.get({
      baseURL,
      url: `/exporting-process/${processId}`,
      headers: { country },
    });
    if (SUCCESS_CODE.includes(response.status)) {
      onSuccess(response.data);
      return undefined;
    }

    onFail();
  } catch (error) {
    onFail();
  }

  return undefined;
};

export default getCSV;
