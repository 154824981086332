import MenuItem from 'Domain/MenuItem';
import ReportTab from 'Domain/ReportTab';
import React from 'react';

export interface IReportsPageContext {
  menuItems: MenuItem[];
  selectedMenuItem: MenuItem;
  defaultTab: ReportTab;
  selectedTab: ReportTab;
}

export const ReportsPageContext = React.createContext<IReportsPageContext | null>(null);
