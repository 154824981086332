import { Select } from '@hexa-ui/components';
import useVendors from 'Services/GetVendors/useVendors';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

interface VendorSelectProps {
  onChange: (value: string) => void;
  selectedValue?: string;
  country?: string;
  serviceModel?: string;
}

function VendorSelector({
  onChange,
  selectedValue,
  country,
  serviceModel,
}: VendorSelectProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { data, isLoading: isLoadingVendors, isFetching } = useVendors({ country, serviceModel });

  const vendors = useMemo(() => {
    const excludedVendors = [
      '86ed84b7-7ef6-47e7-bd86-eb73433b9efb',
      '5b999446-e930-402b-9dc7-f1fe27b93057',
      '362c3511-f149-4fb1-95c0-15c30f539272',
    ];

    return data?.filter((item) => !excludedVendors.includes(item.id));
  }, [data]);

  const sortedVendors = useMemo(() => vendors?.sort((a, b) => a.name.localeCompare(b.name)), [vendors]);

  const isLoading = isFetching && isLoadingVendors;

  const disabled = !country || !serviceModel || isLoading;

  const placeholder = isLoading
    ? formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.VENDOR.LOADING' })
    : formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.VENDOR.PLACEHOLDER' });

  return (
    <Select.Root
      data-testid="workspace-vendor-select"
      label={formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.VENDOR.LABEL' })}
      placeholder={placeholder}
      onChange={onChange}
      value={selectedValue}
      disabled={disabled}
    >
      {sortedVendors?.map((value, index) => (
        <Select.Option
          key={value.id}
          value={`${value.id}|${value.name}`}
          data-testid={`workspace-vendor-select-option-${index}`}
        >
          {value.name}
        </Select.Option>
      ))}
    </Select.Root>
  );
}

export default VendorSelector;
