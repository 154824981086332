import CSVDownloadButton from 'Components/CSVDownloadButton/CSVDownloadButton';
import CSVDownloadModal from 'Components/CSVDownloadModal/CSVDownloadModal';
import HelpCenterModal from 'Components/HelpCenterModal/HelpCenterModal';
import { useHandlerControllers } from 'Hooks/useHandlerControllers/useHandlerControllers';
import { useHelpCenterModal } from 'Hooks/useHelpCenterModal/useHelpCenterModal';
import React, { useCallback, useEffect } from 'react';

export default function CSVDownload(): React.ReactElement {
  const { setIsOpen, isOpen, ...handlerControllers } = useHandlerControllers();
  const {
    onOpenModal,
    onCloseHelpCenterModal,
    onProceedHelpCenterModal,
    isOpenHelpCenterModal,
    openCSVDownloadModal,
    closeCSVDownloadModal,
    indexCSVDownload,
  } = useHelpCenterModal();

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    closeCSVDownloadModal();
  }, [closeCSVDownloadModal, setIsOpen]);

  useEffect(() => {
    if (openCSVDownloadModal) {
      setIsOpen(openCSVDownloadModal);
    }
  }, [openCSVDownloadModal, isOpen, setIsOpen]);

  return (
    <>
      <CSVDownloadButton onClick={onOpenModal} />
      <HelpCenterModal
        isOpen={isOpenHelpCenterModal}
        onClose={onCloseHelpCenterModal}
        onProceed={onProceedHelpCenterModal}
      />
      <CSVDownloadModal
        onClose={handleCloseModal}
        isOpen={isOpen}
        indexCSVDownload={indexCSVDownload}
        {...handlerControllers}
      />
    </>
  );
}
