import { Card as _Card } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const CardContainer = styled(_Card, {
  maxWidth: '100%',
  maxheight: '200px',
  minHeight: '116px',
  padding: '$4 $6',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '$neutral20',
    transition: 'background-color 0.3s ease',
  },
});

const CardContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  justifyContent: 'space-between',
  height: '100%',
});

const Circle = styled('div', {
  width: '40px',
  height: '40px',
  minHeight: '40px',
  borderRadius: '50%',
  backgroundColor: '#FFFF00',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  fontSize: '10px',
  marginBottom: '$4',
});

const Styled = {
  CardContainer,
  CardContent,
  Circle,
};

export default Styled;
