import { Card } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const CardContainer = styled(Card, {
  height: '56px',
  padding: '8px 16px',
  alignItems: 'center',
  minWidth: 0,
  flexDirection: 'row !important',
});

export const ServiceModelAndCountryContainer = styled('div', {
  display: 'none',

  '@media(min-width: 700px)': {
    display: 'flex',
  },
});
