import ErrorPage from 'Components/ErrorPage/ErrorPage';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import { Sidebar } from 'Components/SidebarNew/Sidebar';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import React from 'react';
import { useIntl } from 'react-intl';
import { useBTGuideById } from 'Services/GetBTGuide/useBTGuide';
import { useGetReportsByWorkspace } from 'Services/GetReportsByWorkspace/useGetReportsByWorkspace';
import { useGetWorkspaces } from 'Services/GetWorkspaces/useGetWorkspaces';
import { formatMenuItems } from 'Utils/formatMenuItems/formatMenuItems';
import MainPageContent from './MainPageContent/MainPageContent';

export function MainPage(): React.ReactElement {
  const { formatMessage } = useIntl();
  const { workspaceData: selectedWorkspace } = useWorkspaceContext();

  const {
    data: btGuideUrl,
    isError: isBTGuideError,
    isLoading: isBtGuideLoading,
  } = useBTGuideById(selectedWorkspace?.vendorId, selectedWorkspace?.country ?? 'BR');

  const { data, isLoading: AllWorkspacesIsLoading, error } = useGetWorkspaces();
  const allWorkspaces = data?.workspaces || [];

  const { data: AllReportsMenuItems, isLoading: AllReportsIsLoading } = useGetReportsByWorkspace(
    selectedWorkspace?.workspace?.id,
    selectedWorkspace?.segment?.id,
    selectedWorkspace?.segment?.country
  );

  const sortedMenuItems = formatMenuItems(AllReportsMenuItems?.menuItems || []);

  if (!AllWorkspacesIsLoading && allWorkspaces?.length === 0) {
    return (
      <ErrorPage
        action="ANALYTICS_ADMIN"
        title={formatMessage({ id: 'MAIN_PAGE.EMPTY_STATE.TITLE' })}
        message={formatMessage({ id: 'MAIN_PAGE.EMPTY_STATE.DESCRIPTION' })}
      />
    );
  }

  if (error || !selectedWorkspace?.workspace?.id || isBtGuideLoading) {
    return (
      <PageContainer maxWidth="xl" sidebar={false}>
        <Loading container />
      </PageContainer>
    );
  }

  return (
    <PageContainer maxWidth="xl">
      <Sidebar reportsMenuItems={sortedMenuItems} />
      <MainPageContent
        sortedMenuItems={sortedMenuItems}
        selectedWorkspace={selectedWorkspace}
        isBTGuideError={isBTGuideError}
        btGuideUrl={btGuideUrl}
        AllReportsIsLoading={AllReportsIsLoading}
        allWorkspaces={allWorkspaces}
      />
    </PageContainer>
  );
}
