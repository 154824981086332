import { Breadcrumb } from '@hexa-ui/components';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

export function BreadcrumbComponent(): React.ReactElement {
  const { formatMessage } = useIntl();

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x: string) => x);

  const { themeSelected } = useWorkspaceContext();

  const routeNames = {
    reports: formatMessage({ id: 'MAIN_PAGE.TITLE' }),
    'reports-list': formatMessage({ id: 'REPORTS_LIST_PAGE.TITLE' }),
    'workspaces-list': formatMessage({ id: 'WORKSPACES_PAGE.TITLE' }),
  };

  const isEmbedRoute = () => location.pathname.includes('embed');

  const renderItems = () => {
    if (isEmbedRoute()) {
      return (
        <>
          <Breadcrumb.Item href="/reports" isCurrentPage={false}>
            {formatMessage({ id: 'MAIN_PAGE.TITLE' })}
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/reports/reports-list" isCurrentPage={false}>
            {formatMessage({ id: 'REPORTS_LIST_PAGE.TITLE' })}
          </Breadcrumb.Item>
          <Breadcrumb.Item isCurrentPage>{themeSelected}</Breadcrumb.Item>
        </>
      );
    }

    return pathnames.map((value: keyof typeof routeNames, index: number) => {
      const to = `/${pathnames.slice(0, index + 1).join('/')}`;
      const isCurrent = index === pathnames.length - 1;
      return (
        <Breadcrumb.Item key={to} href={to} isCurrentPage={isCurrent}>
          {routeNames[value]}
        </Breadcrumb.Item>
      );
    });
  };

  return (
    <Breadcrumb.Root>
      <Breadcrumb.HomeItem href="/" />
      {renderItems()}
    </Breadcrumb.Root>
  );
}
