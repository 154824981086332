import { Heading, Paragraph } from '@hexa-ui/components';
import { IconName } from 'Components/IconsMap/IconsMap';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import iconMap from 'Utils/iconMap/iconMap';
import Styled from './CardReports.style';
import { CardProps } from './CardReports.types';

export const GRAY_COLOR = 'rgba(20, 20, 20, 0.56)';

function CardReports({
  icon,
  workspaceName,
  segmentName,
  themeSlug,
  reportSlug,
  fullName,
}: CardProps): React.ReactElement {
  const history = useHistory();

  const renderIcon = useCallback((iconName: IconName) => {
    const IconComponent = iconMap[iconName];
    return IconComponent;
  }, []);

  const handleClick = () => {
    history.push(`/reports/embed/${themeSlug}/${reportSlug}`);
  };

  return (
    <Styled.CardContainer
      border="medium"
      data-testid="report-card"
      id="report-card"
      elevated="minimal"
      onClick={handleClick}
    >
      <Styled.Circle>{renderIcon(icon)}</Styled.Circle>

      <Styled.CardContent>
        <Heading size="H4">{fullName}</Heading>
        <Paragraph size="xsmall" css={{ color: GRAY_COLOR }}>
          {workspaceName}
          {segmentName && ` - ${segmentName}`}
        </Paragraph>
      </Styled.CardContent>
    </Styled.CardContainer>
  );
}

export default CardReports;
