import { useState } from 'react';

interface UseHandlerControllersProps {
  isLoading: boolean;
  setIsLoading: (status: boolean) => void;
  error: string;
  setError: (err: string) => void;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export const useHandlerControllers = (): UseHandlerControllersProps => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return {
    isLoading,
    setIsLoading,
    error,
    setError,
    isOpen,
    setIsOpen,
  };
};
