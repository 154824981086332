import { useCountryService } from 'admin-portal-shared-services';
import { CountryData } from 'admin-portal-shared-services/dist/hooks/useCountryService/useCountryService.types';
import { IconName } from 'Components/IconsMap/IconsMap';

import React, { useCallback } from 'react';
import iconMap from 'Utils/iconMap/iconMap';
import { ImageAndTextContent } from './ImageAndTextContent/ImageAndTextContent';
import { Styled } from './Logo.style';
import { LogoProps } from './Logo.types';

export function Logo({ name, thumbnail = '', type }: LogoProps): React.ReactElement {
  const { data } = useCountryService();
  const flagThumbnail = (data as CountryData[])?.find(
    (country: CountryData) => country.code === thumbnail
  );

  const sanitizedThumbnail = thumbnail?.length === 2 ? flagThumbnail?.flag : encodeURI(thumbnail);

  const renderIcon = useCallback((iconName: IconName) => {
    const IconComponent = iconMap[iconName] || iconMap.Store2;
    return IconComponent;
  }, []);

  return type === 'Icon' ? (
    <Styled.Wrapper data-testid="logo">{renderIcon(thumbnail as IconName)}</Styled.Wrapper>
  ) : (
    <ImageAndTextContent name={name} thumbnail={sanitizedThumbnail} />
  );
}
