import { styled } from '@hexa-ui/theme';
import { Heading } from '@hexa-ui/components';
import React from 'react';

export const HeadingContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',
  margin: '$4 0',

  '>:not(:first-child)': {
    marginBottom: '$4',
  },

  '@media(min-width: 700px)': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '$8 0 $4',

    '>:not(:first-child)': {
      marginBottom: '0',
    },
  },
});

export function PageTitle({ children }: { children: React.ReactNode }): React.ReactElement {
  return (
    <Heading
      id="heading"
      size="H1"
      css={{ '@tablet': { marginRight: '$8' }, whiteSpace: 'nowrap' }}
    >
      {children}
    </Heading>
  );
}
