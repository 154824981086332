import CSVDownloadType from './CSVDownloadType';

export interface CSVDownloadResponse {
  csvExportCode: string;
  type: CSVDownloadType;
  title: Record<string, string>;
}

export default class CSVDownload {
  public readonly csvExportCode: string;

  public readonly type: CSVDownloadType;

  private readonly title: Record<string, string>;

  constructor({ csvExportCode, type, title }: CSVDownloadResponse) {
    this.csvExportCode = csvExportCode;
    this.type = type;
    this.title = title;
  }

  getTitle(locale: string, defaultLocale: string): string {
    return this.title[locale] || this.title[defaultLocale] || this.csvExportCode;
  }
}

export const csvDownloadExample = {
  csvExportCode: 'ORDERS',
  type: CSVDownloadType.LAST_12_MONTHS,
  title: {
    'en-US': 'Orders',
    'pt-BR': 'Pedidos',
    'es-419': 'Pedidos',
    'id-ID': 'Pesanan',
  },
};

export const getCsvDownloadExample = (
  csvDownload: Partial<CSVDownloadResponse> = {}
): CSVDownload => new CSVDownload(getCsvDownloadResponseExample(csvDownload));

export const getCsvDownloadResponseExample = (
  csvDownload: Partial<CSVDownloadResponse>
): CSVDownloadResponse => ({
  ...csvDownloadExample,
  ...csvDownload,
});
