import { Button } from '@hexa-ui/components';
import { ChevronDown } from '@hexa-ui/icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { styled } from '@hexa-ui/theme';

export const StyledButton = styled(Button, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderColor: 'rgba(0, 0, 0, 0.16) !important',
  '&:hover': {
    borderColor: '$neutral100 !important',
    background: 'none !important',
    boxShadow: 'none !important',
  },
  '&[data-state="open"]': {
    borderColor: '$neutral100 !important',
    background: 'none !important',
    boxShadow: 'none !important',
  },
});

export const StyledIconContainer = styled('div', {
  display: 'flex',
  marginRight: '$1',
});

export const StyledChevronDown = styled(ChevronDown, {
  marginLeft: '$2',
});

export const StyledValueContainer = styled('span', {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
  textAlign: 'left',
});

export const StyledDropdownMenuContent = styled(DropdownMenu.Content, {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  boxShadow: '$4',
  borderRadius: '$4',
  backgroundColor: 'white',
  padding: '$1 0 $1 0',
  color: '$interfaceLabelPrimary',
  fontFamily: '$paragraph',
  cursor: 'default',
  fontSize: '$3',
});

export const StyledDropdownMenuItem = styled(DropdownMenu.Item, {
  flexGrow: 1,
  flexShrink: '0',
  minHeight: '56px',
  display: 'flex',
  alignItems: 'center',
  background: '$interfaceSurfaceClear',
  padding: '0 $4',
  '&[data-highlighted]': {
    background: '$brandAccentNeutralBackground',
    outline: 'none',
  },
});
