import { styled } from '@hexa-ui/theme';

export const MainContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

export const ContainerModal = styled('div', {
  width: '576px',
  textAlign: 'center',
  borderRadius: '10px',
  backgroundColor: 'white',
  boxShadow: '0 6px 10px 0 rgba(0,0,0,0.09);',
  '& img': {
    marginTop: '32px',
    marginBottom: '32px',
  },
  '& h3': {
    fontWeight: 'bold',
    marginBottom: '26.40px',
    fontFamily: 'Work Sans',
  },
  '& p': {
    textAlign: 'center',
    width: '521px',
    marginBottom: '21.40px',
  },
  '& Button': {
    marginTop: '19.60px',
    marginBottom: '32px',
    borderRadius: '4px',
  },
});

export const ContainerItem = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});
