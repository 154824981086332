import { styled } from '@hexa-ui/theme';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
});

export const Styled = {
  Wrapper,
};
