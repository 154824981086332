import { Paragraph } from '@hexa-ui/components';
import { UserType } from 'Assets/Constants';
import CrownGold from 'Assets/images/gold.svg';
import CrownSilver from 'Assets/images/silver.svg';
import CountryFlag from 'Components/CountryFlag/CountryFlag';
import PartnerLogo from 'Components/PartnerLogo/PartnerLogo';
import { isSalesManager } from 'Domain/User';
import React from 'react';
import { useIntl } from 'react-intl';
import { TierLogo } from './SelectedVendorName.style';

export function SelectedVendorName({
  vendorName,
  serviceModel,
  tier,
  vendorId,
  country,
}: {
  vendorName: string;
  serviceModel: string;
  tier?: string;
  vendorId: string;
  country: string;
}): React.ReactElement {
  const userIsManager = isSalesManager();
  const { formatMessage } = useIntl();

  return (
    <>
      <PartnerLogo css={{ marginRight: '$4' }} vendorId={vendorId} />
      <Paragraph
        weight="semibold"
        size="small"
        css={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          flexGrow: 1,
          maxWidth: 'max-content',
        }}
      >
        {vendorName}
      </Paragraph>
      {userIsManager ? (
        <>
          <Paragraph css={{ marginLeft: '24px' }} />
          <CountryFlag country={country} />
          <Paragraph
            size="small"
            css={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              flexGrow: 1,
              maxWidth: 'max-content',
            }}
          >
            {formatMessage({ id: `COUNTRIES.${country}` })}
          </Paragraph>
        </>
      ) : (
        serviceModel === '1P' &&
        tier !== undefined && (
          <TierLogo>
            <img
              data-testid={tier === UserType.GOLD_PARTNER ? 'gold-partner' : 'silver-partner'}
              src={tier === UserType.GOLD_PARTNER ? CrownGold : CrownSilver}
              alt={tier === UserType.GOLD_PARTNER ? 'Crown Gold' : 'Crown Silver'}
            />
          </TierLogo>
        )
      )}
    </>
  );
}
