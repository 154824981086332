export enum PowerBIReportStatus {
  LOADING_EMBED_REPORT = 'LOADING_EMBED_REPORT',
  LOADING_POWERBI = 'LOADING_POWERBI',
  LOADING_DATA = 'LOADING_DATA',
  RENDERING_STARTED = 'RENDERING_STARTED',
  RENDERED = 'RENDERED',
}

export interface UsePowerBIReportReturn {
  eventHandlers: Map<string, PowerBIEventHandler>;
  status: PowerBIReportStatus;
  isLoading: boolean;
  trackReportEvent: (eventName: PowerBIReportEvent) => void;
  setStatus: (status: PowerBIReportStatus) => void;
}

export enum PowerBIReportEvent {
  INIT = 'insights.init',
  DATA_LOADED = 'insights.dataLoaded',
  RENDERING_STARTED = 'insights.renderingStarted',
  REPORT_RENDERED = 'insights.reportRendered',
  INTERACTION = 'insights.interaction',
  INTERACTION_RENDERED = 'insights.interactionRendered',
}

export type PowerBIEventHandler = (eventData?: unknown) => void;
