const StatusCode = {
  REQUEST_TIMEOUT: 408,
  PAYLOAD_TOO_LARGE: 413,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVALIABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

export default StatusCode;
