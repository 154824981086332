import Last12MonthsCSVModal from 'Components/CSVDownloadModal/Last12MonthsCSVModal/Last12MonthsCSVModal';
import Last30DaysCSVModal from 'Components/CSVDownloadModal/Last30DaysCSVModal/Last30DaysCSVModal';
import CSVDownloadType from 'Domain/CSVDownloadType';
import { CSVDownload } from 'Domain/NewMenuItem';
import React from 'react';
import { useCSVDownload } from './useCsvDownload';

interface CSVDownloadModalProps {
  isOpen: boolean;
  isLoading: boolean;
  error: string;
  onClose: () => void;
  setError: (error: string) => void;
  setIsLoading: (value: boolean) => void;
  indexCSVDownload: number;
  csvDownload: CSVDownload[];
}

/* istanbul ignore next */
export function CSVDownloadModal({
  isOpen,
  isLoading,
  error,
  onClose,
  setError,
  setIsLoading,
  indexCSVDownload,
  csvDownload,
}: CSVDownloadModalProps): React.ReactElement | null {
  const { downloadCSV } = useCSVDownload({
    csvDownload,
    setError,
    setIsLoading,
    indexCSVDownload,
    onSuccess: () => onClose(),
  });

  const handleFreeTrialDownload = () => {
    downloadCSV();
  };

  const handleGoldDownload = (period: { year: number; month: number }) => {
    downloadCSV(period);
  };

  if (!csvDownload.length) return null;

  if (csvDownload[indexCSVDownload].type === CSVDownloadType.LAST_30_DAYS) {
    return (
      <Last30DaysCSVModal
        isOpen={isOpen}
        isLoading={isLoading}
        isError={!!error}
        onClose={onClose}
        onConfirm={handleFreeTrialDownload}
      />
    );
  }

  return (
    <Last12MonthsCSVModal
      isLoading={isLoading}
      onConfirm={handleGoldDownload}
      isOpen={isOpen}
      onClose={onClose}
      error={error}
      setError={setError}
    />
  );
}
