import { UserInfoToken } from 'admin-portal-shared-services/dist/services/authentication/AuthenticationService.types';
import { UserType } from 'Assets/Constants';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';

export const isPartner = (): boolean => {
  const { loggedUserType } = LoggedUserStore.getState();
  return [UserType.GOLD_PARTNER, UserType.SILVER_PARTNER].includes(loggedUserType);
};
export const isManager = (): boolean => {
  const { loggedUserType } = LoggedUserStore.getState();
  return loggedUserType === UserType.MANAGER;
};
export const isMultiVendor = (): boolean => {
  const { vendors } = LoggedUserStore.getState();
  return isPartner() && vendors.length > 1;
};

export const isManufacturer = (): boolean => {
  const { vendors } = LoggedUserStore.getState();

  return vendors.some((vendor) => vendor.isManufacturer);
};

export const isGlobalPartner = (): boolean => {
  const { loggedUserType } = LoggedUserStore.getState();
  const { vendors } = LoggedUserStore.getState();
  return loggedUserType === UserType.GLOBAL_PARTNER && vendors.length > 1;
};

export const isSalesManager = (): boolean => {
  const { loggedUserType } = LoggedUserStore.getState();
  return loggedUserType === UserType.SALES_MANAGER;
};

export const isGlobalOrMultivendorPartner = (): boolean => isMultiVendor() || isGlobalPartner();

export const emptyToken: UserInfoToken = {
  scopes: [],
  iss: '',
  exp: '',
  nbf: 0,
  aud: '',
  sub: '',
  country: '',
  serviceModel: '',
  governmentId: '',
  preferredLanguage: '',
  email: '',
  name: '',
  given_name: '',
  family_name: '',
  app: '',
  tid: '',
  nonce: '',
  azp: '',
  createdOn: '',
  ver: '',
  iat: 0,
  role: '',
  roles: [],
  extension_tncaccepteddatetime: 0,
  segmentation: [],
};
