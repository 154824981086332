import { GLOBAL_OVERVIEW } from 'Assets/Constants';
import { TitleLoader } from 'Components/ReportsPageWrapper/ReportsPageWrapper.style';
import MenuItem, { getGlobalOverviewMenuItem } from 'Domain/MenuItem';
import { isManager, isMultiVendor } from 'Domain/User';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import { ReportsPageRouteParams } from 'Pages/ReportsPage/ReportsPage';
import useReportsMFEConfig from 'Services/GetReportsMFEConfig/useReportsMFEConfig';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import { useStore } from 'effector-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ReportsPageStatus, UseReportsPageResult } from './useReportsPage.types';

export default function useReportsPage(): UseReportsPageResult {
  const { tab: tabFromURL, menuItem: menuItemFromURL } = useParams<ReportsPageRouteParams>();
  const { vendorId, selectedCountry } = useSelectedVendor();
  const { selectedVendor } = useStore(LoggedUserStore);

  const { formatMessage, locale, defaultLocale } = useIntl();

  const reportsMFEConfig = useReportsMFEConfig({
    vendorId,
    country: selectedCountry,
  });

  const getStatus = (): ReportsPageStatus => {
    const isVendorNotSelected = isManager() && (!vendorId || !selectedCountry);
    if (isVendorNotSelected) {
      return ReportsPageStatus.VENDOR_NOT_SELECTED;
    }

    const hasNoReportTabs =
      !!reportsMFEConfig.data &&
      (reportsMFEConfig.data.menuItems?.length < 1 ||
        reportsMFEConfig.data.menuItems?.[0].reportTabs.length < 1);

    const isError = reportsMFEConfig.isError || !vendorId || !selectedVendor || hasNoReportTabs;
    if (isError) {
      return ReportsPageStatus.ERROR;
    }

    const { isLoading } = reportsMFEConfig;
    if (isLoading) {
      return ReportsPageStatus.LOADING;
    }

    const isInvalidMenuItem = !menuItemFromURL || !selectedMenuItem;
    if (isInvalidMenuItem) {
      return ReportsPageStatus.INVALID_MENU_ITEM;
    }

    const isInvalidTab = !tabFromURL || !selectedTab;
    if (isInvalidTab) {
      return ReportsPageStatus.INVALID_TAB;
    }

    return ReportsPageStatus.LOADED;
  };

  const getTitle = (status: ReportsPageStatus, selectedMenuItem?: MenuItem): React.ReactNode => {
    if (status === ReportsPageStatus.LOADING) {
      return <TitleLoader />;
    }

    if (status === ReportsPageStatus.LOADED) {
      /* istanbul ignore if */
      if (isMultiVendor() && selectedVendor.id === GLOBAL_OVERVIEW) {
        return formatMessage({ id: 'GLOBAL_OVERVIEW_PAGE.GLOBAL_OVERVIEW_PAGE_TITLE' });
      }
      return (selectedMenuItem as MenuItem).getTitle(locale, defaultLocale);
    }

    return formatMessage({ id: 'REPORTS_PAGE.REPORTS_PAGE_TITLE' });
  };

  let menuItems: MenuItem[] = [];

  const isValidOrder = (items: MenuItem[]) => items.every((item) => typeof item.order === 'number');

  const sortMenuItems = (originalMenuItems: MenuItem[]) => {
    if (isValidOrder(originalMenuItems)) {
      return [...originalMenuItems].sort((a, b) => a.order - b.order);
    }
    return originalMenuItems;
  };

  /* istanbul ignore else */
  if (reportsMFEConfig.data) {
    const originalMenuItems = reportsMFEConfig.data.menuItems;
    menuItems = sortMenuItems(originalMenuItems);
    /* istanbul ignore else */
    if (selectedVendor.id !== GLOBAL_OVERVIEW || !isMultiVendor()) {
      menuItems = menuItems?.filter((item) => item.slug !== 'global-overview');
    }
    /* istanbul ignore if */
    if (isMultiVendor() && selectedVendor.id === GLOBAL_OVERVIEW) {
      menuItems = getGlobalOverviewMenuItem();
    }
  }
  const selectedMenuItem = menuItems?.find((item) => item.slug === menuItemFromURL);
  const defaultMenuItem = menuItems?.[0];

  const selectedTab = selectedMenuItem?.reportTabs.find((tab) => tab.slug === tabFromURL);
  const defaultTab = selectedMenuItem?.reportTabs[0];

  const status = getStatus();
  const title = getTitle(status, selectedMenuItem);

  return {
    status,
    title,
    menuItems,
    defaultMenuItem,
    defaultTab,
    selectedMenuItem,
    selectedTab,
    selectedVendor,
  } as UseReportsPageResult;
}
