import { CSVDownload } from 'Domain/NewMenuItem';
import { useHandlerControllers } from 'Hooks/useHandlerControllers/useHandlerControllers';
import React, { useCallback, useEffect } from 'react';
import { CSVDownloadButton } from './CSVDownloadButton';
import { CSVDownloadModal } from './CSVDownloadModal';
import { Styled } from './ContentReportView.style';
import { useHelpCenter } from './useHelpCenter';

interface ContentReportViewProps {
  csvDownload: CSVDownload[];
}

/* istanbul ignore next */
export function ContentReportView({ csvDownload }: ContentReportViewProps): React.ReactElement {
  const { setIsOpen, isOpen, ...handlerControllers } = useHandlerControllers();

  const { onOpenModal, openCSVDownloadModal, closeCSVDownloadModal, indexCSVDownload } =
    useHelpCenter(csvDownload);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    closeCSVDownloadModal();
  }, [closeCSVDownloadModal, setIsOpen]);

  useEffect(() => {
    if (openCSVDownloadModal) {
      setIsOpen(openCSVDownloadModal);
    }
  }, [openCSVDownloadModal, isOpen, setIsOpen]);

  return (
    <Styled.Wrapper>
      <CSVDownloadButton onClick={onOpenModal} />
      <CSVDownloadModal
        csvDownload={csvDownload}
        onClose={handleCloseModal}
        isOpen={isOpen}
        indexCSVDownload={indexCSVDownload}
        {...handlerControllers}
      />
    </Styled.Wrapper>
  );
}
