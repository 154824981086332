import React from 'react';

export type EnvConfig = {
  optimizelyKey: string;
  segmentKey: string;
  supportedCountries?: string;
  partnerLogos?: { [vendorId: string]: string };
  supportedCountriesEurope?: string;
};

export const defaultEnvInitialValue: EnvConfig = {
  optimizelyKey: '',
  segmentKey: '',
};

export const EnvContext = React.createContext(defaultEnvInitialValue);

export function EnvProvider({
  env,
  children,
}: {
  env: EnvConfig;
  children: React.ReactNode;
}): React.ReactElement {
  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
}
