import MFERoutes from 'Config/MFERoutes';
import { MenuItem } from 'Domain/NewMenuItem';

import useAppEvents from 'Hooks/segment/useAppEvents/useAppEvents';
import { SidebarItem } from 'admin-portal-shared-services';

import iconMap from 'Utils/iconMap/iconMap';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Icons } from './icons';

export default function useSidebarItems({
  reportsMenuItems,
}: {
  reportsMenuItems?: MenuItem[];
}): SidebarItem[] {
  const { locale, formatMessage } = useIntl();
  const { triggerMenuInteraction } = useAppEvents();

  return useMemo(() => {
    const items: SidebarItem[] = [
      {
        id: 'main-page',
        icon: Icons.Grid,
        path: MFERoutes.MAIN_PAGE,
        title: formatMessage({ id: 'SIDEBAR.MAIN_PAGE' }),
      },
    ];

    const onPageChange = (menuCategory: string) => () => {
      triggerMenuInteraction(menuCategory);
    };

    /* istanbul ignore if */
    if (reportsMenuItems?.length) {
      reportsMenuItems.forEach((menuItem) =>
        items.push({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          icon: () => iconMap[menuItem.iconString] || Icons.BarChart,
          id: menuItem.id,
          path: `${MFERoutes.HOME}/embed/${menuItem.slug}`,
          title: menuItem.title[locale],
          onPageChange: onPageChange(menuItem.trackingId),
        })
      );
    }

    return items;
  }, [formatMessage, locale, reportsMenuItems, triggerMenuInteraction]);
}
