/* eslint-disable class-methods-use-this */
import analytics from './generated';
import TrackingService from './TrackingService';

class TrackingServiceImp implements TrackingService {
  public getAnalyticsTypewriter(): typeof analytics {
    return analytics;
  }
}

export default TrackingServiceImp;
