import { Card } from '@hexa-ui/components';
import React from 'react';
import { CSS } from '@stitches/react/types/css-util';
import { DefaultContainer } from './DefaultContainer.style';

interface DefaultContainerProps {
  children: React.ReactNode;
  dataTest?: string;
  css?: CSS;
}

function DefaultContainerView({
  children,
  dataTest = 'main-card',
  css,
}: DefaultContainerProps): React.ReactElement {
  return (
    <DefaultContainer>
      <Card
        data-testid={dataTest}
        className="main-container"
        border="medium"
        elevated="small"
        css={css}
      >
        {children}
      </Card>
    </DefaultContainer>
  );
}

export default DefaultContainerView;
