import { styled } from '@hexa-ui/theme';

export const GoldBadgeContainer = styled('span', {
  padding: '0 16px',
  height: '40px',
  borderRadius: '40px',
  backgroundImage: 'linear-gradient(to top, #dcd4f4, #e1daf6, #e6e0f8, #ebe6fa, #f0ecfc)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  color: 'rgba(0, 0, 0, 0.9)',
  fontFamily: 'Work Sans',

  letterSpacing: '0.1px',
  lineHeight: '24px',

  fontSize: '14px',

  '@tablet': {
    fontSize: '16px',
  },
});
