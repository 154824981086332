import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ReportsMFEConfig from 'Domain/ReportsMFEConfig';
import { useCountry } from 'Hooks/useCountry/useCountry';
import getReportsMFEConfigService from 'Services/GetReportsMFEConfig/getReportsMFEConfigService';

interface UseReportsMFEConfigParams {
  vendorId?: string;
  country?: string;
}

const useReportsMFEConfig = ({
  vendorId,
  country,
}: UseReportsMFEConfigParams): UseQueryResult<ReportsMFEConfig> => {
  const countryFromHook = useCountry();

  return useQuery({
    queryKey: ['reportsMFEConfig', vendorId, country],
    queryFn: () =>
      getReportsMFEConfigService({
        vendorId: vendorId as string,
        country: country || countryFromHook,
      }),
    enabled: !!vendorId,
    refetchOnWindowFocus: false,
  });
};

export default useReportsMFEConfig;
