import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import React from 'react';
import { IntlProvider as NativeProvider } from 'react-intl';
import MessageMap from './i18n.d';
import enUS from './languages/en-US';
import es419 from './languages/es-419';
import idID from './languages/id-ID';
import ptBR from './languages/pt-BR';

const DEFAULT_LANGUAGE = 'en-US';
type IIntlProvider = {
  children: any;
};

export const messages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es419,
  'id-ID': idID,
};

const getLanguage: { [language: string]: string } = {
  en: 'en-US',
  pt: 'pt-BR',
  es: 'es-419',
  id: 'id-ID',
};
export function IntlProvider({ children }: IIntlProvider): React.ReactElement {
  const { preferredLanguage } = usePreferredLanguageV2();

  const availableLanguage = preferredLanguage
    ? getLanguage[preferredLanguage.split('-')[0]]
    : undefined;

  const locale = availableLanguage || DEFAULT_LANGUAGE;
  const mergedMessages = mergeMessages(messages, locale);

  return (
    <NativeProvider locale={locale} defaultLocale={DEFAULT_LANGUAGE} messages={mergedMessages}>
      {children}
    </NativeProvider>
  );
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
/* eslint-enable */

const mergeMessages = (messagesInput: MessageMap, selectedLocale: string): any => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
