import permissions from 'Config/permissions';
import { TrackingContext } from 'Context/TrackingContext';
import useUserGroups from 'Hooks/useUserGroups/useUserGroups';
import { useContext } from 'react';
import {
  SegmentCommonProps,
  SegmentScreenName,
  SegmentUserType,
} from './useSegmentCommonProps.types';

const useScreenName = (): SegmentScreenName => SegmentScreenName.PARTNER_INSIGHTS;

const getSegmentUserType = (scopes?: string[]): SegmentUserType => {
  const isManager = scopes?.some(
    (scope) =>
      scope === permissions.PartnerDashboard.MANAGER ||
      scope === permissions.PartnerDashboard['1P'] ||
      scope === permissions.PartnerDashboard['3P'] ||
      scope === permissions.PartnerDashboard.DTaaS
  );

  const isSalesManager = scopes?.includes(permissions.PartnerDashboard.SALES_MANAGER);

  if (isManager) {
    return SegmentUserType.MANAGER;
  }

  if (isSalesManager) {
    return SegmentUserType.SALES_MANAGER;
  }

  return SegmentUserType.PARTNER;
};

export function useSegmentCommonProps(): SegmentCommonProps {
  const screenName = useScreenName();
  const userGroups = useUserGroups();

  const userType = getSegmentUserType(userGroups);
  const trackingContext = useContext(TrackingContext);

  return {
    screen_name: screenName,
    vendor_id: trackingContext?.vendorId ?? null,
    vendor_name: trackingContext?.vendorName ?? null,
    business_model: trackingContext?.businessModel ?? null,
    user_type: userType,
    report_type: trackingContext?.reportType ?? null,
    report_subtype: trackingContext?.reportSubType ?? null,
    tab_name: trackingContext?.tabName ?? null,
    country: trackingContext?.country ?? null,
  };
}
