import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { GetVendorsResponse, getVendorsService } from './getVendorsService';

const totalElementsForVendorList = 500;

interface UseVendorsParams {
  country?: string;
  serviceModel?: string;
}

const useVendors = ({
  country,
  serviceModel,
}: UseVendorsParams): UseQueryResult<GetVendorsResponse[]> =>
  useQuery({
    queryKey: ['vendors', country, serviceModel],
    queryFn: () =>
      getVendorsService({
        customTotalElements: totalElementsForVendorList,
        country,
        serviceModel,
      }),
    enabled: !!country && !!serviceModel,
    refetchOnWindowFocus: false,
  });

export default useVendors;
