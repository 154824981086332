import { Heading, LoadingBuzz, Paragraph } from '@hexa-ui/components';
import DefaultContainerView from 'Components/DefaultContainer/DefaultContainerView';
import React from 'react';
import { useIntl } from 'react-intl';
import { ILoadingProps } from './Loading.d';
import { BuzzLoading, FullScreen, LoadingTitle } from './Loading.style';

const Loading = ({
  children,
  isLoading = true,
  container = false,
  fullscreen = false,
  title,
  body,
}: ILoadingProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const LoadingContent = (
    <div>
      <BuzzLoading data-testid="loadingBuzz">
        <LoadingBuzz color="mono" size="xxlarge" />
      </BuzzLoading>
      <LoadingTitle data-testid="loadingBuzzTitle">
        <Heading size="H5" alignment="center" data-testid="loadingTitle">
          {title || formatMessage({ id: 'GET_STARTED.LOADING' })}
        </Heading>
      </LoadingTitle>
      <Paragraph alignment="center" size="basis" data-testid="loadingParagraph">
        {body || formatMessage({ id: 'LOADING.PARAGRAPH' })}
      </Paragraph>
    </div>
  );

  const renderLoading = () => {
    if (fullscreen) {
      return <FullScreen data-testid="fullscreen">{LoadingContent}</FullScreen>;
    }

    if (container) {
      return (
        <DefaultContainerView
          css={{
            minHeight: '429px',
          }}
          dataTest="container"
        >
          {LoadingContent}
        </DefaultContainerView>
      );
    }

    return LoadingContent;
  };

  return <>{isLoading ? renderLoading() : children}</>;
};

export default Loading;
