import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { useMemo } from 'react';

const MANAGER_SERVICE_MODEL_SCOPES: { [key: string]: string } = {
  'PartnerDashboard.InsightsManager1P.Read': '1P',
  'PartnerDashboard.InsightsManager3PPRO.Read': '3P-3L-SS',
  'PartnerDashboard.InsightsManagerDTAAS.Read': '3P-3L-SF',
};

export default function useSupportedServiceModels(): string[] {
  const userScopes = useUserScopes();

  const supportedServiceModels = useMemo(
    () =>
      userScopes?.reduce<string[]>((arr, el) => {
        if (MANAGER_SERVICE_MODEL_SCOPES[el]) arr.push(MANAGER_SERVICE_MODEL_SCOPES[el]);
        return arr;
      }, []),
    [userScopes]
  );

  return supportedServiceModels ?? [];
}
