import { Heading, Paragraph } from '@hexa-ui/components';
import LogoBeeVertical from 'Assets/images/bee_vertical.svg';
import React from 'react';
import { useIntl } from 'react-intl';
import DefaultContainerView from '../DefaultContainer/DefaultContainerView';
import { Content, Image } from './GenericErrorView.style';

function GenericErrorView(): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <DefaultContainerView
      dataTest="generic-error"
      css={{
        minHeight: '429px',
      }}
    >
      <Image src={LogoBeeVertical} alt="Bee" />
      <Content>
        <Heading size="H4" alignment="center" className="errorTitle">
          {formatMessage({ id: 'CONTAINER_REPORTS.CONTAINER_ERROR' })}
        </Heading>
        <Paragraph size="basis" alignment="center" className="errorMessage">
          {formatMessage({ id: 'CONTAINER_REPORTS.CONTAINER_ERROR_LABEL' })}
        </Paragraph>
      </Content>
    </DefaultContainerView>
  );
}

export default GenericErrorView;
