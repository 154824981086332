import AppModal from 'Components/AppModal/AppModal';
import CsvFileGuideLink from 'Components/CsvFileGuideLink/CsvFileGuideLink';
import React from 'react';
import { useIntl } from 'react-intl';
import { HelpCenterModalProps } from './HelpCenterModal.d';
import { HelpCenterModalDescription } from './HelpCenterModal.style';

const HelpCenterModal = ({
  isOpen,
  onClose,
  onProceed,
}: HelpCenterModalProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <AppModal
      isOpen={isOpen}
      onConfirmButton={onProceed}
      onClose={onClose}
      buttonConfirmLabel="HELP_CENTER_MODAL.PROCEED_BUTTON"
      title="HELP_CENTER_MODAL.TITLE"
      css={{ width: '100%', maxWidth: '534px' }}
      removeButtonCancel
    >
      <>
        <HelpCenterModalDescription>
          {formatMessage({ id: 'HELP_CENTER_MODAL.DESCRIPTION' })}
        </HelpCenterModalDescription>
        <CsvFileGuideLink />
      </>
    </AppModal>
  );
};

export default HelpCenterModal;
