import { AppErrorBoundary } from 'Components/AppErrorBoundary/AppErrorBoundary';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    return <AppErrorBoundary err={error} />;
  },
});

export const { bootstrap, mount, unmount } = reactLifecycles;
