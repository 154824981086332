/* eslint-disable @typescript-eslint/no-explicit-any */
import { EmptyState } from '@hexa-ui/components';
import { ANALYTICS_ADMIN_GROUP } from 'Assets/Constants';
import PageContainer from 'Components/PageContainer/PageContainer';
import { Sidebar } from 'Components/SidebarNew/Sidebar';
import MFERoutes from 'Config/MFERoutes';
import { useReports } from 'Hooks/useReports/useReports';
import useUserGroups from 'Hooks/useUserGroups/useUserGroups';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { hasAdminPermission } from 'Utils/hasAdminPermission/hasAdminPermission';
import { Styled } from './ErrorPage.styles';
import { ErrorsPageProps } from './ErrorPage.types';

export function ErrorPage({
  action = 'NONE',
  title = 'Oops',
  message = 'Something went wrong!',
}: ErrorsPageProps): React.ReactElement {
  const { formatMessage } = useIntl();

  const { sortedMenuItems } = useReports();

  const history = useHistory();

  const userGroups = useUserGroups();
  const hasAnalyticsAdminPermission = hasAdminPermission(userGroups, ANALYTICS_ADMIN_GROUP);

  const showButton = {
    ANALYTICS_ADMIN: hasAnalyticsAdminPermission
      ? [
          {
            action: () => history.push(MFERoutes.ANALYTICS_ADMIN),
            name: formatMessage({ id: 'HEADER.ANALYTICS_BUTTON' }),
          },
        ]
      : [],
    NONE: [],
  };

  return (
    <PageContainer maxWidth="xl">
      <Sidebar reportsMenuItems={sortedMenuItems} />

      <Styled.CenterContainer data-testid="error-full-component">
        <EmptyState.PageLevel
          title={title}
          description={message}
          actions={showButton[action] as any}
        />
      </Styled.CenterContainer>
    </PageContainer>
  );
}

export default ErrorPage;
