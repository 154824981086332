import { Paragraph, TextLink } from '@hexa-ui/components';
import { ChangeWorkspaceModal } from 'Components/ChangeWorkspaceModal/ChangeWorkspaceModal';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import { useScreenSize } from 'Hooks/useScreenSize/useScreenSize';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Logo } from '../Logo/Logo';
import { Styled } from './WorkspaceSelectedCard.style';

export function WorkspaceSelectorCard(): React.ReactElement {
  const { formatMessage, locale: SELECTED_LANGUAGE } = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const { workspaceData, setTempWorkspaceId } = useWorkspaceContext();

  const handleToggleModal = () => {
    setTempWorkspaceId('');
    setIsOpen(!isOpen);
  };

  const hideVendorName = () => workspaceData?.segment?.name === workspaceData?.vendorName;

  const thumb = workspaceData?.segment?.thumbnail;
  const name = workspaceData?.segment?.name || '';

  const [isTablet] = useScreenSize(['tablet']);

  return (
    <>
      <ChangeWorkspaceModal isOpen={isOpen} onClose={handleToggleModal} />

      <Styled.CardContainer border="small" elevated="small" isTablet={isTablet}>
        <Paragraph css={{ whiteSpace: 'nowrap' }}>
          {formatMessage({ id: 'WORKSPACE_SELECTED_CARD.VIEW' })}
        </Paragraph>

        <Logo type={thumb?.type} thumbnail={thumb?.value} name={name} />
        <Styled.Item data-testid="segment-name" size="basis" weight="semibold">
          {workspaceData?.segment?.name ||
            formatMessage({ id: 'WORKSPACE_SELECTED_CARD.NO_SEGMENT' })}
        </Styled.Item>

        {!isTablet && (
          <>
            <Styled.Divider />

            <Styled.Item data-testid="workspace-name">
              {workspaceData?.workspace?.title[SELECTED_LANGUAGE]}
            </Styled.Item>
          </>
        )}

        {hideVendorName() ||
          (!isTablet && workspaceData?.vendorName && (
            <>
              <Styled.Divider />

              <Styled.Item data-testid="workspace-vendor-name">
                {workspaceData?.vendorName}
              </Styled.Item>
            </>
          ))}

        <Styled.Divider css={{ backgroundColor: 'transparent' }} />

        <TextLink onClick={handleToggleModal} hasUnderline={false}>
          {formatMessage({ id: 'WORKSPACE_SELECTED_CARD.CHANGE' })}
        </TextLink>
      </Styled.CardContainer>
    </>
  );
}
