import { CSS } from '@stitches/react/types/css-util';
import { PowerBIReport } from 'Components/EmbedReport/PowerBIReport/PowerBIReport';
import GenericErrorView from 'Components/GenericError/GenericErrorView';
import useRenderEvents from 'Hooks/segment/useRenderEvents/useRenderEvents';
import useReportsEvents from 'Hooks/segment/useReportsEvents/useReportsEvents';
import useReportsPage from 'Hooks/useReportsPage/useReportsPage';
import { ReportsPageStatus } from 'Hooks/useReportsPage/useReportsPage.types';
import useEmbedReport from 'Services/EmbedReport/useEmbedReport';
import React, { useEffect } from 'react';

interface EmbedReportProps {
  vendorId: string;
  lang: string;
  reportCode: string;
  css?: CSS;
  globalOverview?: boolean;
  country?: string;
}

export function EmbedReport({
  vendorId,
  lang,
  reportCode,
  css,
  globalOverview,
  country,
}: EmbedReportProps): React.ReactElement {
  const { status } = useReportsPage();

  const { triggerPartnerReportsViewed } = useReportsEvents();
  const embedReport = useEmbedReport({
    vendorId,
    reportCode,
    globalOverview,
    country,
    lang: lang.split('-')[0],
  });
  const { triggerInitRenderingProcess } = useRenderEvents();

  useEffect(() => {
    if (embedReport.data) {
      triggerPartnerReportsViewed();
      if (status === ReportsPageStatus.LOADED) triggerInitRenderingProcess();
    }
  }, [embedReport.data, triggerPartnerReportsViewed, status, triggerInitRenderingProcess]);

  if (embedReport.isError) {
    return <GenericErrorView />;
  }

  return (
    <PowerBIReport
      key={embedReport.data?.reportCode}
      embedUrl={embedReport.data?.embedUrl}
      embedToken={embedReport.data?.embedToken}
      reportId={embedReport.data?.reportId}
      lang={lang}
      css={css}
    />
  );
}
