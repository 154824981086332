import { MenuItem } from 'Domain/NewMenuItem';

export function formatMenuItems(menuItems: MenuItem[]): MenuItem[] {
  const filteredSortedMenuItems = menuItems
    .filter((menuItem) => menuItem.reportTabs.length > 0)
    .map((menuItem) => ({
      ...menuItem,
      reportTabs: [...menuItem.reportTabs].sort((a, b) => a.order - b.order),
    }))
    .sort((a, b) => a.order - b.order);

  return filteredSortedMenuItems;
}

export const getReportsByTheme = (slug: string, menuItems: MenuItem[]): MenuItem | undefined =>
  menuItems.find((menuItem) => menuItem.slug === slug);
