import React from 'react';
import AppModal from 'Components/AppModal/AppModal';
import SvgRender from 'Components/SvgRender/SvgRender';
import crown_icon from 'Assets/images/crown_icon.svg';
import { AlertMessage } from 'Components/CSVDownloadModal/Last12MonthsCSVModal/Last12MonthsCSVModal.style';
import { Alert, Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { styled } from '@hexa-ui/theme';

interface MobileModalProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  isError: boolean;
  onConfirm: () => void;
}

const HeaderImageContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  background: '#EEE5FF',
  padding: '$8 0',
});

function MobileModal({
  isOpen,
  onClose,
  isLoading,
  isError,
  onConfirm,
}: MobileModalProps): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <AppModal
      removeButtonCancel
      isOpen={isOpen}
      onClose={onClose}
      buttonConfirmLabel="SELECT_RANGE_DATE_CSV_MODAL.DOWNLOAD"
      isLoading={isLoading}
      dataTestId="silver-free-trial-csv-modal"
      headerImage={
        <HeaderImageContainer>
          <SvgRender height="126px" svg={crown_icon} />
        </HeaderImageContainer>
      }
      title="CSV_SILVER_MODAL.TITLE"
      onConfirmButton={onConfirm}
    >
      {isError && (
        <AlertMessage>
          <Alert
            data-testid="error-alert"
            type="error"
            message={formatMessage({ id: 'CSV_SILVER_MODAL.NO_INFO_AVAILABLE' })}
            className="alert-message"
          />
        </AlertMessage>
      )}
      <Paragraph data-testid="first-message-modal" css={{ marginBottom: '$2' }}>
        {formatMessage({ id: 'CSV_SILVER_MODAL.FIRST_DESCRIPTION' })}
      </Paragraph>
      <Paragraph data-testid="second-message-modal">
        {formatMessage({ id: 'CSV_SILVER_MODAL.SECOND_DESCRIPTION' })}
      </Paragraph>
    </AppModal>
  );
}

export default MobileModal;
