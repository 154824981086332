const names: Record<string, string> = {
  nestle: 'Nestlé',
  gpa: 'GPA',
  gloria: 'Gloria',
  mercasid: 'Mercasid',
  brf: 'BRF',
  pepsico: 'PepsiCo',
  gloriaco: 'Gloria',
};

export function getPartnerNameById(
  id: string,
  partnerLogos: {
    [vendorId: string]: string;
  }
): string | undefined {
  const lowerCaseName = partnerLogos[id];
  return lowerCaseName ? names[lowerCaseName] : undefined;
}
