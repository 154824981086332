import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';

interface VendorIdServiceParams {
  pageSize?: number;
  customTotalElements: number;
  country?: string;
  serviceModel?: string;
}

export type GetVendorsResponse = {
  id: string;
  name: string;
  isManufacturer?: boolean;
};

export const getVendorsService = async (
  params: VendorIdServiceParams
): Promise<GetVendorsResponse[]> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_VENDORS, params.country);

  let headers = {};
  if (params.country) {
    headers = { country: params.country };
    delete params.country;
  }

  const response = await Api.get({ url: '/vendors', params, baseURL, headers });
  return response.data.vendors;
};
