import { useAuthenticationService } from 'admin-portal-shared-services';
import { ReportsPageRouteParams } from 'Pages/ReportsPage/ReportsPage';
import { useParams } from 'react-router-dom';

export const useCountry = (): string => {
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

  const urlParams = useParams<ReportsPageRouteParams>();

  return urlParams.country || userCountry || 'BR';
};
