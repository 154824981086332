import { FEATURE_TOGGLE_V2_PROJECT_NAME } from 'Assets/Constants';
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';

let initializedToggles = false;

export default function initFeatureToggles(optimizelyKey: string): void {
  if (initializedToggles) {
    return;
  }

  createFeatureToggleServiceV2(FEATURE_TOGGLE_V2_PROJECT_NAME, optimizelyKey || '');
  initializedToggles = true;
}
