import CSVDownloadSelect from 'Components/CSVDownloadButton/CSVDownloadSelect/CSVDownloadSelect';
import CSVGoldButton from 'Components/CSVDownloadButton/CSVGoldButton/CSVGoldButton';
import CSVSilverButton from 'Components/CSVDownloadButton/CSVSilverButton/CSVSilverButton';
import CSVDownloadType from 'Domain/CSVDownloadType';
import useSelectedTab from 'Hooks/useSelectedTab/useSelectedTab';
import React from 'react';

interface CSVDownloadButtonProps {
  onClick: (index: number) => void;
}

export default function CSVDownloadButton({
  onClick,
}: CSVDownloadButtonProps): React.ReactElement | null {
  const selectedTab = useSelectedTab();

  const handleSingleCSVDownloadClick = () => {
    onClick(0);
  };

  if (!selectedTab.csvDownload.length) return null;

  if (selectedTab.csvDownload.length > 1) {
    return <CSVDownloadSelect onSelect={onClick} csvDownload={selectedTab.csvDownload} />;
  }

  return selectedTab.csvDownload[0].type === CSVDownloadType.LAST_12_MONTHS ? (
    <CSVGoldButton onClick={handleSingleCSVDownloadClick} />
  ) : (
    <CSVSilverButton onClick={handleSingleCSVDownloadClick} />
  );
}
