import { useAnalyticsService } from 'admin-portal-shared-services';
import { UserInfoToken } from 'admin-portal-shared-services/dist/services/authentication/AuthenticationService.types';
import permissions from 'Config/permissions';
import { isPartner } from 'Domain/User';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { useCallback } from 'react';

interface IdentifyTraits {
  country: string;
  email: string;
  first_name: string;
  last_name: string;
  vendor_id?: string;
  manufacturer_id?: string;
  service_model?: string;
}

interface UseIdentifyReturn {
  triggerIdentify: (jwtDecoded: UserInfoToken) => void;
}

export default function useIdentify(): UseIdentifyReturn {
  const analyticsService = useAnalyticsService();
  const { selectedVendor } = useSelectedVendor();
  const userScopes = useUserScopes();

  const triggerIdentify = useCallback(
    (jwtDecoded: UserInfoToken) => {
      if (!userScopes || userScopes.includes(permissions.PartnerDashboard.GLOBAL)) {
        return;
      }

      const traits: IdentifyTraits = {
        country: jwtDecoded.country,
        email: jwtDecoded.email,
        first_name: jwtDecoded.given_name,
        last_name: jwtDecoded.family_name,
      };

      if (jwtDecoded.vendorId && isPartner() && selectedVendor.data) {
        const vendor = selectedVendor.data;

        traits.service_model = vendor.serviceModel;

        if (vendor.is3P) {
          traits.vendor_id = jwtDecoded.vendorId;
        } else {
          traits.manufacturer_id = jwtDecoded.vendorId;
        }
      }

      analyticsService.identify(traits);
    },
    [analyticsService, selectedVendor.data, userScopes]
  );

  return {
    triggerIdentify,
  };
}
