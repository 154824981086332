import Api from 'Api/Api';
import { Workspace, Workspaces } from 'Domain/Workspaces';

export const getWorkspaces = async (): Promise<Workspaces> => {
  const baseUrl = 'partner-analytics-business-service';

  const [responseGlobal, responseEurope] = await Promise.allSettled([
    Api.get({
      url: '/config/user-workspaces',
      baseURL: `/api/${baseUrl}`,
    }),
    Api.get({
      url: '/config/user-workspaces',
      baseURL: `/api/eu/${baseUrl}`,
    }),
  ]);

  const workspacesGlobal =
    responseGlobal.status === 'fulfilled' ? responseGlobal.value.data.workspaces : [];
  const workspacesEurope =
    responseEurope.status === 'fulfilled' ? responseEurope.value.data.workspaces : [];

  const response = {
    workspaces: [
      ...workspacesGlobal.map((workspace: Workspace) => ({ ...workspace, region: 'Global' })),
      ...workspacesEurope.map((workspace: Workspace) => ({ ...workspace, region: 'Europe' })),
    ],
  };

  return response;
};
