/* eslint-disable max-params */
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { MenuItensResponse } from 'Domain/NewMenuItem';
import { getReportsByWorkspace } from './getReportsByWorkspace';

export const useGetReportsByWorkspace = (
  workspaceId?: string,
  permissionId?: string,
  country?: string
): UseQueryResult<MenuItensResponse> =>
  useQuery({
    queryKey: ['reportsByWorkspace', workspaceId, permissionId],
    queryFn: () =>
      getReportsByWorkspace(workspaceId as string, permissionId as string, country as string),
    enabled: !!workspaceId && !!permissionId,
    refetchOnWindowFocus: false,
    staleTime: 7500,
  });
