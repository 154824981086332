import { ReportsPageContext } from 'Context/ReportsPageContext';
import ReportTab from 'Domain/ReportTab';
import { useContext } from 'react';

export default function useReportTabs(): ReportTab[] {
  const reportsPageContext = useContext(ReportsPageContext);

  if (!reportsPageContext) {
    throw new Error(
      'useReportTabs must be used inside a ReportsPageContext.Provider or be mocked in unit tests'
    );
  }

  return reportsPageContext.selectedMenuItem.reportTabs;
}
