import { styled } from '@hexa-ui/theme';

export const Actions = styled('div', {
  display: 'flex',
  justifyContent: 'end',
  marginTop: '20px',

  '.btn-action': {
    fontWeight: 500,
  },
});
