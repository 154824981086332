import { styled } from '@hexa-ui/theme';

export const DefaultContainer = styled('div', {
  '.main-container': {
    margin: '24px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    borderRadius: '16px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.16)',
  },
});
