import { Select } from '@hexa-ui/components';
import CountryFlag from 'Components/CountryFlag/CountryFlag';
import { EnvContext } from 'Components/EnvProvider';
import MultiVendorSelect from 'Components/MultiVendorSelect/MultiVendorSelect';
import { useCountry } from 'Hooks/useCountry/useCountry';
import { setSelectedVendor } from 'Store/LoggedUser/LoggedUserEvents';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import { useStore } from 'effector-react';
import React, { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getPartnerNameById } from './utils/getPartnerNameById';

interface CountrySelectProps {
  selectedValue: string;
  manufacturerIds: string;
}

function CountryMultipleVendorsSelect({
  selectedValue,
  manufacturerIds,
}: CountrySelectProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const defaultCountry = useCountry();
  const { vendors, selectedVendor } = useStore(LoggedUserStore);
  const { partnerLogos } = useContext(EnvContext);

  const partnersVendors = vendors.map((vendor) => ({
    id: vendor.id || '',
    country: vendor.country || '',
    displayName: vendor.displayName || '',
  }));

  const mainVendorLabel = partnerLogos && getPartnerNameById(selectedVendor.id, partnerLogos);

  const options = useMemo(
    () =>
      partnersVendors.map((value) => {
        const countryLabel = formatMessage({ id: `COUNTRIES.${value.country}` });
        /* istanbul ignore next */
        const vendorLabel = value.displayName !== mainVendorLabel ? value.displayName : '';
        return {
          label: `${countryLabel} ${vendorLabel}`,
          value,
        };
      }),
    [mainVendorLabel, partnersVendors]
  );

  const handleSelectVendor = useCallback((vendorId: string) => {
    const vendorCountry = partnersVendors.find((vendor) => vendor.id === vendorId)?.country;
    setSelectedVendor({
      id: vendorId,
      country: vendorCountry || defaultCountry,
    });
  }, []);

  const mappedOptions = (
    <>
      {options.map(({ value, label }) => (
        <Select.Option data-testid={`country-select-${value}`} key={value.id} value={value.id}>
          <CountryFlag country={value.country} />
          <Select.Label>{label}</Select.Label>
        </Select.Option>
      ))}
    </>
  );
  return (
    <MultiVendorSelect
      dataTestId="multivendor-select"
      onChange={handleSelectVendor}
      value={selectedValue}
      defaultValue={manufacturerIds}
      vendor={{
        displayName: mainVendorLabel || '',
        id: selectedVendor.id,
      }}
    >
      {mappedOptions}
    </MultiVendorSelect>
  );
}

export default CountryMultipleVendorsSelect;
