import { Tabs } from '@hexa-ui/components';
import { generateReportsPath } from 'Config/MFERoutes';
import IReportTab from 'Domain/ReportTab';
import useReportsEvents from 'Hooks/segment/useReportsEvents/useReportsEvents';
import useSelectedMenuItem from 'Hooks/useSelectedMenuItem/useSelectedMenuItem';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

interface ReportTabProps {
  reportTab: IReportTab;
}

const ReportTab = React.forwardRef<HTMLButtonElement, ReportTabProps>(
  ({ reportTab }, ref): React.ReactElement => {
    const { locale, defaultLocale } = useIntl();
    const { triggerTabInteraction } = useReportsEvents();
    const history = useHistory();
    const { selectedVendor, selectedCountry } = useSelectedVendor();
    const selectedMenuItem = useSelectedMenuItem();
    const [allowTabInteraction, setAllowTabInteraction] = useState(false);

    const handleTabClick = useCallback(() => {
      setAllowTabInteraction(true);
      history.push({
        pathname: generateReportsPath({
          vendorId: selectedVendor.data?.id,
          tab: reportTab.slug,
          menuItem: selectedMenuItem.slug,
          country: selectedCountry,
        }),
      });
    }, [reportTab.slug, history, selectedVendor.data?.id, selectedMenuItem.slug, selectedCountry]);

    useEffect(() => {
      const hasTabInteraction = allowTabInteraction;
      setAllowTabInteraction(false);
      if (hasTabInteraction) triggerTabInteraction();
    }, [history, allowTabInteraction, setAllowTabInteraction, triggerTabInteraction]);

    return (
      <Tabs.Trigger
        id={`TAB_${reportTab.reportCode}`}
        data-testid={`TAB_${reportTab.reportCode}`}
        value={reportTab.slug}
        onClick={handleTabClick}
        ref={ref}
      >
        {reportTab.getTitle(locale, defaultLocale)}
      </Tabs.Trigger>
    );
  }
);

ReportTab.displayName = 'ReportTab';

export default ReportTab;
