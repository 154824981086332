import useSegment from 'Hooks/useSegment/useSegment';
import { useSegmentCommonProps } from '../useSegmentCommonProps/useSegmentCommonProps';

interface PartnerSelectedProps {
  business_model: string | null;
  country: string | null;
  vendor_id: string | null;
  vendor_name: string | null;
}

interface UseReportsEventsReturn {
  triggerPartnerReportsViewed: () => void;
  triggerTabInteraction: () => void;
  triggerPartnerSelected: (props: PartnerSelectedProps) => void;
  triggerChangePartnerButtonClicked: () => void;
}

export default function useReportsEvents(): UseReportsEventsReturn {
  const { partnerReportsViewed, tabInteraction, partnerSelected, changePartnerButtonClicked } =
    useSegment();
  const {
    screen_name,
    business_model,
    country,
    user_type,
    vendor_id,
    vendor_name,
    report_subtype,
    report_type,
    tab_name,
  } = useSegmentCommonProps();

  const triggerPartnerReportsViewed = () => {
    partnerReportsViewed({
      business_model,
      country,
      report_subtype,
      report_type,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
    });
  };

  const triggerTabInteraction = () => {
    tabInteraction({
      tab_name,
      business_model,
      country,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
    });
  };

  const triggerPartnerSelected = (props: PartnerSelectedProps) => {
    const { business_model, country, vendor_id, vendor_name } = props;
    partnerSelected({
      ...props,
      business_model,
      country,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
    });
  };

  const triggerChangePartnerButtonClicked = () => {
    changePartnerButtonClicked({
      business_model,
      country,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
      report_type,
      tab_name,
    });
  };

  return {
    triggerPartnerReportsViewed,
    triggerTabInteraction,
    triggerPartnerSelected,
    triggerChangePartnerButtonClicked,
  };
}
