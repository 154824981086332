import { styled } from '@hexa-ui/theme';

export const CsvFileGuideLinkContainer = styled('a', {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  color: '#047AF1',
  textDecoration: 'none',
  width: 'fit-content',
  marginBottom: '32px',
});

export const CsvFileGuideLinkText = styled('span', {
  color: '#000000',
  fontFamily: 'Work Sans',
  fontSize: '16px',
  fontWeight: '500',
  letterSpacing: '0',
  lineHeight: '24px',
});
