import { Button } from '@hexa-ui/components';
import { Download } from '@hexa-ui/icons';
import React from 'react';
import { CSVGoldButtonProps } from './CSVGoldButton.types';

function DownloadIcon() {
  return <Download size="medium" data-testid="download-icon" />;
}

export function CSVGoldButton({ ...rest }: CSVGoldButtonProps): React.ReactElement {
  return (
    <Button
      {...rest}
      data-testid="csv-download-gold"
      icon={DownloadIcon}
      leading
      size="medium"
      trailing={false}
      variant="primary"
      id="CSV-button"
      css={{
        flexShrink: '0',
      }}
    >
      CSV
    </Button>
  );
}

export default CSVGoldButton;
