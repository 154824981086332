import { MFE_NAME } from 'Assets/Constants';
import { isManager } from 'Domain/User';
import { generatePath } from 'react-router-dom';

const MFERoutes = {
  MAIN_PAGE: `${MFE_NAME}`,
  WORKSPACES_LIST: `${MFE_NAME}/workspaces-list`,
  REPORTS_MANAGER: `${MFE_NAME}/:country?/:vendorId?/:menuItem?/:tab?`,
  REPORTS_PARTNER: `${MFE_NAME}/:menuItem?/:tab?`,
  HOME: MFE_NAME,
  ANALYTICS_ADMIN: `/analytics-admin`,
  REPORTS_LIST: `${MFE_NAME}/reports-list`,
  EMBED_REPORTS: `${MFE_NAME}/embed/:themeSlug/:reportSlug?`,
} as const;

export const getReportsRoute = (): string =>
  isManager() ? MFERoutes.REPORTS_MANAGER : MFERoutes.REPORTS_PARTNER;

export const generateReportsPath = ({
  vendorId,
  menuItem,
  tab,
  country,
}: {
  vendorId?: string;
  menuItem?: string;
  tab?: string;
  country?: string;
}): string => generatePath(getReportsRoute(), { vendorId, menuItem, tab, country });

export default MFERoutes;
