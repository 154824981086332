const permissions = {
  PartnerDashboard: {
    // READ: 'PartnerDashboard.DB.Read',
    // '1P': 'PartnerDashboard.InsightsManager1P.Read',
    // '3PPRO': 'PartnerDashboard.InsightsManager3PPRO.Read',
    // DTaaS: 'PartnerDashboard.InsightsManagerDTAAS.Read',
    GLOBAL: 'PartnerDashboard.InsightsGlobalManagerViewer.Read',
    // SALESMANAGER: 'PartnerDashboard.SalesManagerViewer.Read',
    MANAGER: 'PartnerDashboard.BEES viewer',
    '1P': 'PartnerDashboard.1P viewer',
    '3P': 'PartnerDashboard.3P Pro viewer',
    DTaaS: 'PartnerDashboard.DTaaS viewer',
    SALES_MANAGER: 'PartnerDashboard.Sales manager',
    MANUFACTURER: 'PartnerDashboard.Global manufacturer viewer',
    SELLER_VIEWER: 'PartnerDashboard.Seller viewer',
  },
};
export default permissions;
