async function generateKeyFromString(keyString: string) {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(keyString);

  const hash = await crypto.subtle.digest('SHA-256', keyData);

  return crypto.subtle.importKey(
    'raw',
    hash,
    {
      name: 'AES-GCM',
    },
    false,
    ['encrypt', 'decrypt']
  );
}

async function encryptData(keyString: string, data: string): Promise<string> {
  const key = await generateKeyFromString(keyString);

  const encoder = new TextEncoder();
  const encodedData = encoder.encode(data);

  const iv = crypto.getRandomValues(new Uint8Array(12));

  const encryptedData = await crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv,
    },
    key,
    encodedData
  );

  const combinedData = new Uint8Array(iv.byteLength + encryptedData.byteLength);
  combinedData.set(iv, 0);
  combinedData.set(new Uint8Array(encryptedData), iv.byteLength);

  return btoa(String.fromCharCode(...combinedData));
}

async function decryptData(keyString: string, encryptedString: string): Promise<string> {
  const key = await generateKeyFromString(keyString);

  const combinedData = Uint8Array.from(atob(encryptedString), (char) => char.charCodeAt(0));

  const iv = combinedData.slice(0, 12);
  const ciphertext = combinedData.slice(12);

  const decryptedData = await crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv,
    },
    key,
    ciphertext
  );

  const decoder = new TextDecoder();
  return decoder.decode(decryptedData);
}

export { decryptData, encryptData };
