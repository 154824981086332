import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 56px)',
  '& > div:nth-child(2)': {
    flex: 1,
    overflow: 'auto',
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
});

export const Styled = {
  Container,
  Wrapper,
};
