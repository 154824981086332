import { useLocalStorage } from 'admin-portal-shared-services';
import CSVDownloadType from 'Domain/CSVDownloadType';
import useCSVEvents from 'Hooks/segment/useCSVEvents/useCSVEvents';
import useSelectedTab from 'Hooks/useSelectedTab/useSelectedTab';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import { useCallback, useMemo, useState } from 'react';

interface UseHelpCenterModal {
  onOpenModal: (index: number) => void;
  onCloseHelpCenterModal: () => void;
  onProceedHelpCenterModal: () => void;
  isOpenHelpCenterModal: boolean;
  openCSVDownloadModal: boolean;
  closeCSVDownloadModal: () => void;
  indexCSVDownload: number;
}

export const useHelpCenterModal = (): UseHelpCenterModal => {
  const [isOpenHelpCenterModal, setIsOpenHelpCenterModal] = useState<boolean>(false);
  const [openCSVDownloadModal, setOpenCSVDownloadModal] = useState<boolean>(false);
  const [indexCSVDownload, setIndexCSVDownload] = useState<number>(0);
  const [showHelpCenterModal, setShowHelpCenterModal] = useLocalStorage(
    'insights-show-helpcenter-modal',
    true
  );

  const { selectedVendor } = useSelectedVendor();
  const selectedTab = useSelectedTab();

  const { triggerCsvDownloadStarted } = useCSVEvents();

  const isShowHelpCenterModal = useMemo(() => {
    if (!showHelpCenterModal) return false;
    return (
      selectedVendor.data?.serviceModel === '1P' &&
      selectedTab.csvDownload[indexCSVDownload] &&
      selectedTab.csvDownload[indexCSVDownload].type !== CSVDownloadType.BECOME_GOLD
    );
  }, [selectedVendor.data, selectedTab.csvDownload, showHelpCenterModal, indexCSVDownload]);

  const onOpenCsvModal = useCallback(() => {
    if (
      selectedTab.csvDownload[indexCSVDownload] &&
      selectedTab.csvDownload[indexCSVDownload].type !== CSVDownloadType.BECOME_GOLD
    ) {
      triggerCsvDownloadStarted();
    }

    setOpenCSVDownloadModal(true);
  }, [selectedTab.csvDownload, triggerCsvDownloadStarted, indexCSVDownload]);

  const onOpenModal = useCallback(
    (indexStr = 0) => {
      setIndexCSVDownload(Number(indexStr));
      if (isShowHelpCenterModal) {
        setIsOpenHelpCenterModal(true);
        return;
      }
      onOpenCsvModal();
    },
    [isShowHelpCenterModal, onOpenCsvModal]
  );

  const onCloseHelpCenterModal = useCallback(() => {
    setIsOpenHelpCenterModal(false);
    setShowHelpCenterModal(false);
    setOpenCSVDownloadModal(false);
  }, [setIsOpenHelpCenterModal, setShowHelpCenterModal]);

  const onProceedHelpCenterModal = useCallback(() => {
    setIsOpenHelpCenterModal(false);
    setShowHelpCenterModal(false);
    onOpenCsvModal();
  }, [setShowHelpCenterModal, onOpenCsvModal]);

  const closeCSVDownloadModal = useCallback(() => {
    setIsOpenHelpCenterModal(false);
    setShowHelpCenterModal(false);
    setOpenCSVDownloadModal(false);
  }, [setShowHelpCenterModal, setOpenCSVDownloadModal]);

  return {
    onOpenModal,
    onCloseHelpCenterModal,
    onProceedHelpCenterModal,
    isOpenHelpCenterModal,
    openCSVDownloadModal,
    closeCSVDownloadModal,
    indexCSVDownload,
  };
};
