import { Select } from '@hexa-ui/components';
import useSupportedServiceModels from 'Hooks/useSupportedServiceModels/useSupportedServiceModels';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

interface ServiceModelSelectProps {
  onChange: (value: string) => void;
  selectedValue?: string;
}

function ServiceModelSelector({
  onChange,
  selectedValue,
}: ServiceModelSelectProps): React.ReactElement {
  const { formatMessage } = useIntl();

  const serviceModels = useSupportedServiceModels();

  const options = useMemo(
    () =>
      serviceModels
        .map((value) => ({ value, label: formatMessage({ id: `SERVICE_MODELS.${value}` }) }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [formatMessage, serviceModels]
  );

  return (
    <Select.Root
      data-testid="workspace-service-model-select"
      label={formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.SERVICE_MODEL.LABEL' })}
      placeholder={formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.SERVICE_MODEL.PLACEHOLDER' })}
      onChange={onChange}
      value={selectedValue}
    >
      {options?.map(({ value, label }) => (
        <Select.Option
          data-testid={`workspace-service-model-select-${value}`}
          key={value}
          value={value}
        >
          {label}
        </Select.Option>
      ))}
    </Select.Root>
  );
}

export default ServiceModelSelector;
