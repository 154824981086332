import { styled } from '@hexa-ui/theme';

const CenterContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

export const Styled = {
  CenterContainer,
};
