import useSegment from 'Hooks/useSegment/useSegment';
import { useSegmentCommonProps } from '../useSegmentCommonProps/useSegmentCommonProps';

interface UseRenderEventsReturn {
  triggerInitRenderingProcess: () => void;
  triggerInteractionRenderedProcess: () => void;
}

export default function useRenderEvents(): UseRenderEventsReturn {
  const { initRenderingProcess, interactionRenderedRenderingProcess } = useSegment();
  const {
    screen_name,
    business_model,
    country,
    user_type,
    vendor_id,
    vendor_name,
    report_subtype,
    report_type,
  } = useSegmentCommonProps();

  const triggerInitRenderingProcess = () => {
    initRenderingProcess({
      business_model,
      country,
      report_subtype,
      report_type,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
    });
  };

  const triggerInteractionRenderedProcess = () => {
    interactionRenderedRenderingProcess({
      business_model,
      country,
      report_subtype,
      report_type,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
    });
  };

  return {
    triggerInitRenderingProcess,
    triggerInteractionRenderedProcess,
  };
}
