import CSVDownload, { CSVDownloadResponse } from './CSVDownload';

export interface ReportTabResponse {
  reportCode: string;
  csvDownload: CSVDownloadResponse[];
  slug: string;
  trackingId: string;
  title: Record<string, string>;
  viewMode: string;
}

export default class ReportTab {
  public readonly reportCode: string;

  public readonly csvDownload: CSVDownload[];

  public readonly slug: string;

  public readonly trackingId: string;

  private readonly title: Record<string, string>;

  public readonly viewMode: string;

  constructor({ reportCode, csvDownload, slug, trackingId, title, viewMode }: ReportTabResponse) {
    this.reportCode = reportCode;
    this.slug = slug;
    this.trackingId = trackingId;
    this.title = title;
    this.csvDownload = csvDownload.map((csvOption) => new CSVDownload(csvOption));
    this.viewMode = viewMode;
  }

  getTitle(locale: string, defaultLocale: string): string {
    return this.title[locale] || this.title[defaultLocale] || this.reportCode;
  }
}

export const tabExample = {
  reportCode: 'ORDERS',
  slug: 'orders',
  trackingId: 'ORDERS',
  csvDownload: [],
  title: {
    'en-US': 'Orders',
    'pt-BR': 'Pedidos',
    'es-419': 'Pedidos',
    'id-ID': 'Pesanan',
  },
  viewMode: 'TAB',
};

export const getReportTabExample = (tab: Partial<ReportTabResponse> = {}): ReportTab =>
  new ReportTab(getReportTabResponseExample(tab));

export const getReportTabResponseExample = (
  tab: Partial<ReportTabResponse>
): ReportTabResponse => ({
  ...tabExample,
  ...tab,
});
