import React, { useState } from 'react';

import { Heading } from '@hexa-ui/components';

import { getInitials } from 'Utils/getInitials/getInitials';

import { Styled } from '../Logo.style';
import { ImageAndTextContentProps } from './ImageAndTextContent.types';

export function ImageAndTextContent({
  name,
  thumbnail,
}: ImageAndTextContentProps): React.ReactElement {
  const [imageLoaded, setImageLoaded] = useState(true);
  const handleImageError = () => {
    setImageLoaded(false);
  };

  return (
    <Styled.Wrapper data-testid="circle">
      {imageLoaded && thumbnail ? (
        <img
          src={thumbnail}
          alt={`${name} background`}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '50%',
            backgroundColor: 'yellow',
            backgroundImage: `url(${thumbnail})`,
          }}
          onError={handleImageError}
        />
      ) : (
        <Heading size="H5">{getInitials(name)}</Heading>
      )}
    </Styled.Wrapper>
  );
}
