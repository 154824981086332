import { UserType } from 'Assets/Constants';
import useSelectedTab from 'Hooks/useSelectedTab/useSelectedTab';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import React from 'react';
import { useIntl } from 'react-intl';
import { GoldBadgeContainer } from './ExclusiveGoldBadge.style';

function ExclusiveGoldBadge(): React.ReactElement | null {
  const { formatMessage } = useIntl();
  const loggedUserType = LoggedUserStore.getState();

  const { selectedVendor } = useSelectedVendor();

  const usersAllowedToViewComponent = [UserType.GOLD_PARTNER, UserType.MANAGER];
  const isPartnerSelected = !!selectedVendor;

  const selectedTab = useSelectedTab();
  const isRevenueTabSelected = selectedTab.reportCode === 'REVENUE';

  if (
    usersAllowedToViewComponent.includes(loggedUserType.loggedUserType) &&
    isPartnerSelected &&
    isRevenueTabSelected
  ) {
    return (
      <GoldBadgeContainer data-testid="exclusive-gold-badge">
        {formatMessage({ id: 'BADGES.EXCLUSIVE_FOR_GOLD_PARTNER' })}
      </GoldBadgeContainer>
    );
  }

  return null;
}

export default ExclusiveGoldBadge;
