import { Alert, Button, Heading, Paragraph } from '@hexa-ui/components';
import { useMatchMedia } from '@hexa-ui/theme';
import crown_icon from 'Assets/images/crown_icon.svg';
import AppModal from 'Components/AppModal/AppModal';
import { AlertMessage } from 'Components/CSVDownloadModal/Last12MonthsCSVModal/Last12MonthsCSVModal.style';
import MobileModal from 'Components/CSVDownloadModal/Last30DaysCSVModal/MobileModal/MobileModal';
import SvgRender from 'Components/SvgRender/SvgRender';
import useCSVEvents from 'Hooks/segment/useCSVEvents/useCSVEvents';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import BackgroundImage from './BackgroundImage/BackgroundImage';
import { ContainerItem, ContainerModal, MainContainer } from './Last30DaysCSVModal.style';

interface Last30DaysCSVModalProps {
  isOpen: boolean;
  isLoading: boolean;
  isError: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function Last30DaysCSVModal({
  isOpen,
  isLoading,
  isError,
  onClose,
  onConfirm,
}: Last30DaysCSVModalProps): React.ReactElement {
  const { triggerCsvDownloadCanceled } = useCSVEvents();
  const { formatMessage } = useIntl();

  const handleCloseModal = useCallback(() => {
    triggerCsvDownloadCanceled();
    onClose();
  }, [onClose, triggerCsvDownloadCanceled]);

  const [isMobile] = useMatchMedia(['mobile']);

  return isMobile ? (
    <MobileModal
      isLoading={isLoading}
      onClose={handleCloseModal}
      isOpen={isOpen}
      isError={isError}
      onConfirm={onConfirm}
    />
  ) : (
    <AppModal
      removeButtonConfirm
      removeButtonCancel
      isOpen={isOpen}
      onClose={handleCloseModal}
      isLoading={isLoading}
      css={{ width: '842px', minHeight: '530px', position: 'relative', top: '-50px', margin: '0' }}
      dataTestId="silver-free-trial-csv-modal"
      headerImage={<BackgroundImage />}
    >
      <MainContainer>
        <ContainerModal>
          <SvgRender height="126px" svg={crown_icon} />
          <Heading size="H3" alignment="center" data-testid="title-modal-silver">
            {formatMessage({ id: 'CSV_SILVER_MODAL.TITLE' })}
          </Heading>
          {isError && (
            <AlertMessage>
              <Alert
                data-testid="error-alert"
                type="error"
                message={formatMessage({ id: 'CSV_SILVER_MODAL.NO_INFO_AVAILABLE' })}
                className="alert-message"
              />
            </AlertMessage>
          )}
          <ContainerItem>
            <Paragraph data-testid="first-message-modal">
              {formatMessage({ id: 'CSV_SILVER_MODAL.FIRST_DESCRIPTION' })}
            </Paragraph>
          </ContainerItem>
          <ContainerItem>
            <Paragraph data-testid="second-message-modal">
              {formatMessage({ id: 'CSV_SILVER_MODAL.SECOND_DESCRIPTION' })}
            </Paragraph>
          </ContainerItem>
          <ContainerItem>
            <Button
              data-testid="confirm-button"
              size="large"
              isLoading={isLoading}
              onClick={onConfirm}
            >
              {formatMessage({ id: 'CSV_SILVER_MODAL.BUTTON' })}
            </Button>
          </ContainerItem>
        </ContainerModal>
      </MainContainer>
    </AppModal>
  );
}
