import { EnvContext } from 'Components/EnvProvider';
import React, { useContext } from 'react';
import { CSS } from '@stitches/react/types/css-util';
import { PartnerLogoContainer, PartnerLogoImg } from './PartnerLogo.style';
import PartnerLogoSrcMap from './PartnerLogoSrcMap';

function PartnerLogo({
  vendorId,
  css,
}: {
  vendorId?: string;
  css?: CSS;
}): React.ReactElement | null {
  const { partnerLogos } = useContext(EnvContext);

  if (!partnerLogos || !vendorId) {
    return null;
  }

  if (partnerLogos[vendorId]) {
    const partnerLogoSrc = PartnerLogoSrcMap.get(partnerLogos[vendorId]);
    return (
      <PartnerLogoContainer data-testid="partner-logo" css={css}>
        <PartnerLogoImg src={partnerLogoSrc} alt="Partner Logo" />
      </PartnerLogoContainer>
    );
  }

  return null;
}

export default PartnerLogo;
