import { SegmentedControl, Tabs } from '@hexa-ui/components';
import { MenuItem } from 'Domain/NewMenuItem';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { HeaderEmbedProps } from './HeaderEmbed.types';

export function HeaderEmbed({
  reportSlug,
  reportsByTheme,
  themeSelected,
  themeSlug,
  lang,
}: HeaderEmbedProps): React.ReactElement {
  const history = useHistory();
  const handleChangeTab = (reportSlug: string) => {
    history.push(`/reports/embed/${themeSlug}/${reportSlug}`);
  };

  const options = (themeSelected: MenuItem, lang: string) => {
    const arr: { label: string; value: string }[] = [];
    themeSelected?.reportTabs.map((tab) => arr.push({ label: tab.title[lang], value: tab.slug }));
    return arr;
  };

  const toggleOptions = options(themeSelected, lang);

  const handleToggleClick = (reportSlug: string) => {
    history.push(`/reports/embed/${themeSlug}/${reportSlug}`);
  };

  return themeSelected?.viewMode === 'TAB' ? (
    <Tabs.Root data-testid="tabs-container" defaultValue={reportSlug}>
      <Tabs.List>
        {reportsByTheme?.map((report) => (
          <Tabs.Trigger
            key={report.id}
            value={report.slug}
            onClick={() => handleChangeTab(report.slug)}
          >
            {report.title[lang]}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
    </Tabs.Root>
  ) : (
    <SegmentedControl
      data-testid="toggle-container"
      defaultValue={toggleOptions.find((option) => option.value === reportSlug)?.value}
      options={toggleOptions}
      onValueChange={(value) => handleToggleClick(value)}
    />
  );
}
