import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Workspaces } from 'Domain/Workspaces';
import { getWorkspaces } from './getWorkspaces';

export const useGetWorkspaces = (): UseQueryResult<Workspaces> => {
  const result = useQuery({
    queryKey: ['workspaces'],
    queryFn: () => getWorkspaces(),
    refetchOnWindowFocus: false,
  });

  return result;
};
