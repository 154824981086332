import { BookOpen } from '@hexa-ui/icons';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import { useAuthenticationService } from 'admin-portal-shared-services';
import React from 'react';
import { useIntl } from 'react-intl';
import { CsvFileGuideLinkContainer, CsvFileGuideLinkText } from './CsvFileGuideLink.style';
import { linkByCountry } from './linkByCountry';

function CsvFileGuideLink(): React.ReactElement | null {
  const { formatMessage } = useIntl();

  const authService = useAuthenticationService();
  const { user_country } = authService.getUserCountryAndLanguage();
  const country = user_country || 'BR';
  const linkToGuide = linkByCountry[country];

  const { selectedVendor } = useSelectedVendor();

  if (!selectedVendor.data || selectedVendor.data.serviceModel !== '1P') {
    return null;
  }

  return (
    <CsvFileGuideLinkContainer href={linkToGuide} target="_blank" data-testid="csvfileguide">
      <BookOpen size="medium" />
      <CsvFileGuideLinkText>{formatMessage({ id: 'HELP_CENTER_MODAL.LINK' })}</CsvFileGuideLinkText>
    </CsvFileGuideLinkContainer>
  );
}

export default CsvFileGuideLink;
