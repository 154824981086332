import { Tabs } from '@hexa-ui/components';
import { GLOBAL_OVERVIEW } from 'Assets/Constants';
import { EmbedReport } from 'Components/EmbedReport/EmbedReport';
import useAppEvents from 'Hooks/segment/useAppEvents/useAppEvents';
import useDefaultTab from 'Hooks/useDefaultTab/useDefaultTab';
import useReportTabs from 'Hooks/useReportTabs/useReportTabs';
import useSelectedTab from 'Hooks/useSelectedTab/useSelectedTab';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { isGlobalPartner, isMultiVendor } from '../../../../domain/User';
import ContentReportsTabsView from './ContentReportsToggle/ContentReportsToggleView';
import ReportToggle from './ReportToggle/ReportToggle';

function ReportsToggleComponent(): React.ReactElement | null {
  const { triggerPageViewed } = useAppEvents();
  const pageViewedTriggered = useRef<boolean>(false);

  useEffect(() => {
    /* istanbul ignore if */
    if (pageViewedTriggered.current) return;

    triggerPageViewed({
      URL: window.location.href,
      path: window.location.pathname,
      category: 'REPORTS',
    });

    pageViewedTriggered.current = true;
  }, [triggerPageViewed]);

  const { locale: selectedLocale, defaultLocale } = useIntl();
  const locale = selectedLocale || defaultLocale;

  const { selectedVendor } = LoggedUserStore.getState();
  const { vendorId, selectedCountry } = useSelectedVendor();

  const selectedTab = useSelectedTab();
  const defaultTab = useDefaultTab();
  const reportTabs = useReportTabs();

  const options = () => {
    const arr: { label: string; value: string }[] = [];
    reportTabs.map((tab) =>
      arr.push({ label: tab.getTitle(locale, defaultLocale), value: tab.slug })
    );
    return arr;
  };

  return (
    <>
      {reportTabs.length > 1 && (
        <Tabs.Root
          id="toggles"
          data-testid="toggles-container"
          className="toggles"
          defaultValue={defaultTab.slug}
          value={selectedTab.slug}
          style={{ marginBottom: '24px' }}
        >
          <ReportToggle reportToggle={options()} />
        </Tabs.Root>
      )}

      <>
        <ContentReportsTabsView />
        <EmbedReport
          vendorId={
            (isMultiVendor() && selectedVendor.id !== GLOBAL_OVERVIEW) || isGlobalPartner()
              ? selectedVendor.id
              : (vendorId as string)
          }
          lang={locale}
          reportCode={selectedTab.reportCode}
          globalOverview={selectedTab.reportCode === GLOBAL_OVERVIEW}
          country={selectedCountry}
        />
      </>
    </>
  );
}

export default ReportsToggleComponent;
