export const hasAdminPermission = (
  userScopes: string[] | undefined,
  requiredScopes: string[]
): boolean => {
  if (!userScopes) {
    return false;
  }

  return requiredScopes.every((scope) => userScopes.includes(scope));
};
