import { SearchField } from '@hexa-ui/components';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { ChangeWorkspaceModal } from 'Components/ChangeWorkspaceModal/ChangeWorkspaceModal';
import { EmptyState } from 'Components/EmptyState/EmptyState';
import ErrorPage from 'Components/ErrorPage/ErrorPage';
import { HeaderContainer } from 'Components/HeaderContainer/HeaderContainer';
import PageContainer from 'Components/PageContainer/PageContainer';
import { Sidebar } from 'Components/SidebarNew/Sidebar';
import { WorkspaceCard } from 'Components/WorkspaceCard/WorkspaceCard';
import MFERoutes from 'Config/MFERoutes';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetReportsByWorkspace } from 'Services/GetReportsByWorkspace/useGetReportsByWorkspace';
import { useGetWorkspaces } from 'Services/GetWorkspaces/useGetWorkspaces';
import { formatMenuItems } from 'Utils/formatMenuItems/formatMenuItems';
import { moveItemToFirstPosition } from 'Utils/sortWorkspaces/sortWorkspaces';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import { Styled } from './WorkspacesPage.style';

interface WorkspacePageProps {
  notFoundDataState?: boolean;
}

export function WorkspacesPage({
  notFoundDataState,
}: Readonly<WorkspacePageProps>): React.ReactElement {
  const { formatMessage } = useIntl();
  const { preferredLanguage } = usePreferredLanguageV2();

  const DEBOUNCE_DELAY = 1;

  const [searchWorkspace, setSearchWorkspace] = useState('');

  const debouncedSearchWorkspace = useDebounce(searchWorkspace, DEBOUNCE_DELAY);

  const [isOpen, setIsOpen] = useState(false);

  const { workspaceData: selectedWorkspace, setTempWorkspaceId } = useWorkspaceContext();

  const { data, isLoading } = useGetWorkspaces();
  const allWorkspaces = data?.workspaces || [];

  const selectedWorkspaceId = selectedWorkspace?.workspace?.id || '';

  const sortedWorkspaces = moveItemToFirstPosition(selectedWorkspaceId, allWorkspaces);

  const { data: AllReportsMenuItems } = useGetReportsByWorkspace(
    selectedWorkspace?.workspace?.id,
    selectedWorkspace?.segment?.id,
    selectedWorkspace?.segment?.country
  );

  const sortedMenuItems = formatMenuItems(AllReportsMenuItems?.menuItems || []);

  const filteredWorkspaces = useMemo(
    () =>
      sortedWorkspaces?.filter((workspace) =>
        workspace.title[preferredLanguage ?? 'en-US']
          .toLowerCase()
          .includes(debouncedSearchWorkspace.toLowerCase())
      ),
    [debouncedSearchWorkspace, preferredLanguage, sortedWorkspaces]
  );

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWorkspace(event.target.value);
  }, []);

  const handleClear = () => {
    setSearchWorkspace('');
  };

  /* istanbul ignore next */
  const handleToggleModal = (id?: string) => {
    if (id) {
      setTempWorkspaceId(id);
    }

    setIsOpen(!isOpen);
  };

  const noFilteredData = filteredWorkspaces?.length === 0;

  if (!isLoading && allWorkspaces?.length === 0) {
    return (
      <ErrorPage
        title={formatMessage({ id: 'MAIN_PAGE.EMPTY_STATE.TITLE' })}
        message={formatMessage({ id: 'MAIN_PAGE.EMPTY_STATE.DESCRIPTION' })}
      />
    );
  }

  return (
    <>
      <ChangeWorkspaceModal isOpen={isOpen} onClose={handleToggleModal} />
      <PageContainer maxWidth="xl">
        <Sidebar reportsMenuItems={sortedMenuItems} />
        <HeaderContainer
          pageTitle={formatMessage({ id: 'WORKSPACES_PAGE.TITLE' })}
          goBackButtonUrl={MFERoutes.MAIN_PAGE}
        />

        <Styled.Container>
          <Styled.SearchFieldContainer>
            <SearchField.Root
              placeholder={formatMessage({ id: 'WORKSPACES_PAGE.SEARCHFIELD.PLACEHOLDER' })}
              value={searchWorkspace}
              onChange={handleSearch}
              onClear={handleClear}
            />
          </Styled.SearchFieldContainer>
          {!isLoading && (noFilteredData || notFoundDataState) ? (
            <EmptyState
              css={{ padding: '$24 0', margin: '0 auto' }}
              title={formatMessage({ id: 'REPORTS_LIST_PAGE.NOT_FOUND_STATE.TITLE' })}
              text={formatMessage({ id: 'REPORTS_LIST_PAGE.NOT_FOUND_STATE.TEXT' })}
            />
          ) : (
            <Styled.GridContainer>
              {filteredWorkspaces?.map((workspace) => {
                const isSelectedWorkspace = workspace.id === selectedWorkspace?.workspace?.id;
                return (
                  <WorkspaceCard
                    key={workspace.id}
                    title={workspace.title[preferredLanguage ?? 'en-US']}
                    segments={workspace.permissions.length}
                    isSelected={isSelectedWorkspace}
                    onClick={/* istanbul ignore next */ () => handleToggleModal(workspace.id)}
                  />
                );
              })}
            </Styled.GridContainer>
          )}
        </Styled.Container>
      </PageContainer>
    </>
  );
}
