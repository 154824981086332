/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios from 'axios';

export interface IRequest {
  url: string;
  params?: React.ReactNode;
  body?: React.ReactNode;
  baseURL?: string;
  headers?: { [key: string]: string };
}
const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

export const initialAxios = Axios.create({
  headers: {
    common: {
      country: userCountry || 'BR',
    },
  },
});

authentication.enhancedAxios(initialAxios);

const Api = {
  post: ({ url, body, baseURL, params, headers }: IRequest): Promise<any> =>
    initialAxios.post(url, body, { params, baseURL, headers }),

  delete: ({ url, baseURL }: IRequest): Promise<any> => initialAxios.delete(url, { baseURL }),

  get: ({ url, params, baseURL, headers }: IRequest): Promise<any> =>
    initialAxios.get(url, { params, baseURL, headers }),

  put: ({ url, body, baseURL }: IRequest): Promise<any> => initialAxios.put(url, body, { baseURL }),

  patch: ({ url, body, baseURL }: IRequest): Promise<any> =>
    initialAxios.patch(url, body, { baseURL }),
};

export default Api;
