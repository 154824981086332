import MenuItem from 'Domain/MenuItem';
import { useSidebar } from 'admin-portal-shared-services';
import useSidebarItems from './useSidebarItems';
import useSidebarUtils from './useSidebarUtils';

function Sidebar({ reportsMenuItems }: { reportsMenuItems?: MenuItem[] }): null {
  const items = useSidebarItems({ reportsMenuItems });
  const utils = useSidebarUtils();

  useSidebar({ items, utils });

  return null;
}

export default Sidebar;
