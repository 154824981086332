import { Carousel } from '@hexa-ui/components';
import CardReports from 'Components/CardReports/CardReports';
import CardSection from 'Components/CardSection/CardSection';
import MFERoutes from 'Config/MFERoutes';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import { useScreenSize } from 'Hooks/useScreenSize/useScreenSize';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { GetReportTabs } from 'Utils/getReportsTab/getReportsTab';
import { hideSpaces } from 'Utils/hideSpaces/hideSpaces';
import { Styled } from '../MainPage.style';
import { ReportsSectionProps } from './ReportsSection.types';

export function ReportsSection({
  hasBTGuide,
  segmentIdSelected,
  workspaceNameSelected,
  allReportsByTheme,
  isLoading,
}: ReportsSectionProps): React.ReactElement {
  const { formatMessage } = useIntl();

  const { workspaceData } = useWorkspaceContext();
  const allPermissions = workspaceData?.workspace?.permissions;

  const getPermissionName = useMemo(
    () => (id: string) => {
      const permission = allPermissions?.find((perm) => perm.id === id);
      return permission?.name;
    },
    [allPermissions]
  );

  const reportsToShow = hasBTGuide ? 6 : /* istanbul ignore next */ 8;

  const allReportsItems = GetReportTabs(allReportsByTheme);
  const filteredReports = allReportsItems.slice(0, reportsToShow);

  const [isMobile, isTablet, isDesktopSmall] = useScreenSize(['mobile', 'tablet', 'desktopSmall']);

  const shouldHideSpaces = hideSpaces({
    isMobile,
    isTablet,
    isDesktopSmall,
    data: filteredReports,
    hasBtGuide: hasBTGuide,
  });

  const getCarouselSize = () => {
    if (isMobile) return 'xl';
    if (isTablet) return 'lg';
    if (hasBTGuide || isDesktopSmall) return 'md';
    return 'sm';
  };

  return (
    <CardSection
      title={formatMessage({ id: 'MAIN_PAGE.REPORT_SECTION.TITLE' })}
      subtitle={formatMessage({ id: 'MAIN_PAGE.REPORT_SECTION.SUBTITLE' })}
      hasRedirect={allReportsItems.length > reportsToShow}
      redirectText={formatMessage({ id: 'MAIN_PAGE.REPORT_SECTION.BUTTON' })}
      pagePath={`${MFERoutes.REPORTS_LIST}`}
      isLoading={isLoading}
      isEmpty={allReportsItems.length === 0}
      key={`${isMobile}-${isTablet}-${isDesktopSmall}`}
    >
      {filteredReports?.length > 0 && (
        <Styled.ContainerCarousel hideSpaces={shouldHideSpaces}>
          <Carousel.Root size={getCarouselSize()} swipeable={isTablet} autoHideIndicators>
            {filteredReports?.map((item) => (
              <CardReports
                key={item.id}
                fullName={item.fullName}
                icon={item.iconString}
                workspaceName={workspaceNameSelected}
                segmentName={segmentIdSelected && getPermissionName(segmentIdSelected)}
                themeSlug={item.themeSlug}
                reportSlug={item.reportSlug}
              />
            ))}
          </Carousel.Root>
        </Styled.ContainerCarousel>
      )}
    </CardSection>
  );
}
