import { styled } from '@hexa-ui/theme';

export const HelpCenterModalDescription = styled('div', {
  fontFamily: 'Work Sans',
  marginTop: '12px',
  marginBottom: '16px',
  fontSize: '16px',
  color: 'rgba(0,0,0,0.9)',
  letterSpacing: '0.1px',
  lineHeight: '24px',
});
