import { SkeletonLoader } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React from 'react';

export function TitleLoader(): React.ReactElement {
  return <SkeletonLoader width="150px" height="2.5rem" />;
}

export const MultiVendorSelectWrapper = styled('div', {
  minWidth: '312px',
});

const TabsLoadingContainer = styled('div', {
  display: 'flex',
  marginBottom: '36px',
  marginTop: '16px',
});

export function TabsLoader(): React.ReactElement {
  return (
    <TabsLoadingContainer>
      <SkeletonLoader width="100px" height="32px" />
    </TabsLoadingContainer>
  );
}
