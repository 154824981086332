import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';

interface BTGuideParams {
  vendorId: string | null | undefined;
  country: string;
}

const getBTGuideService = async (params: BTGuideParams): Promise<any> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_BTGUIDE_BY_ID, params.country);

  const response = await Api.get({
    url: `/bt-guide/${params.vendorId}`,
    headers: {
      country: params.country,
    },
    baseURL,
  });

  return response.data;
};

export default getBTGuideService;
