import { SidebarUtilitiesItem, useSidebar } from 'admin-portal-shared-services';
import { MenuItem } from 'Domain/NewMenuItem';
import useSidebarItems from './useSidebarItems';

export function Sidebar({ reportsMenuItems }: { reportsMenuItems?: MenuItem[] }): null {
  const items = useSidebarItems({ reportsMenuItems: reportsMenuItems || [] });
  const utils: SidebarUtilitiesItem[] = [];

  useSidebar({ items, utils });

  return null;
}
