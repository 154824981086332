import { styled } from '@hexa-ui/theme';

export const PartnerLogoContainer = styled('div', {
  height: '40px',
  width: '40px',
  backgroundColor: '#F2F2F2',
  borderRadius: '4px',
  padding: '6px 2px',
});

export const PartnerLogoImg = styled('img', {
  height: '28px',
  width: '36px',
  backgroundColor: '#F2F2F2',
});
