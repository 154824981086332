import { Download } from '@hexa-ui/icons';
import CrownIcon from 'Assets/Icons/CrownIcon.svg';
import SvgRender from 'Components/SvgRender/SvgRender';
import React from 'react';
import { CSVSilverButtonContainer } from './CSVSilverButton.style';
import { CSVSilverButtonProps } from './CSVSilverButton.types';

function CSVSilverButton({ onClick }: CSVSilverButtonProps): React.ReactElement {
  return (
    <CSVSilverButtonContainer
      onClick={onClick}
      data-testid="csv-download-silver"
      aria-label="download csv silver"
    >
      <Download size="large" />

      <span>CSV</span>
      <SvgRender svg={CrownIcon} alt="CrownIcon" />
    </CSVSilverButtonContainer>
  );
}

export default CSVSilverButton;
