import useSegment from 'Hooks/useSegment/useSegment';
import { differenceInSeconds, format, getYear } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { useRef } from 'react';
import { useSegmentCommonProps } from '../useSegmentCommonProps/useSegmentCommonProps';

export interface CSVEvents {
  triggerCSVDownloadSuccessful: () => void;
  triggerCSVDownloadRequested: (params?: CSVRequestedParams) => void;
  triggerCsvDownloadFailed: (failureReason: string) => void;
  triggerCsvDownloadStarted: () => void;
  triggerCsvDownloadCanceled: () => void;
}

interface CSVRequestedParams {
  month: number;
  year: number;
}

export default function useCSVEvents(): CSVEvents {
  const {
    csvDownloadCanceled,
    csvDownloadRequestFailed,
    csvDownloadRequestSuccessful,
    csvDownloadRequested,
    csvDownloadStarted,
  } = useSegment();

  const {
    screen_name,
    business_model,
    country,
    user_type,
    vendor_id,
    vendor_name,
    report_subtype,
    report_type,
    tab_name,
  } = useSegmentCommonProps();

  const downloadRequested = useRef<Date>();

  const downloadMonth = useRef<string | null>(null);

  const downloadYear = useRef<number | null>(null);

  const triggerCSVDownloadSuccessful = () => {
    const loadTime = downloadRequested.current
      ? String(differenceInSeconds(new Date(), downloadRequested.current))
      : null;

    csvDownloadRequestSuccessful({
      csv_download_month: downloadMonth.current,
      csv_download_year: downloadYear.current,
      load_time: loadTime,
      business_model,
      country,
      report_subtype,
      report_type,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
      tab_name,
    });
  };

  const triggerCSVDownloadRequested = (period?: CSVRequestedParams) => {
    const formatMonth = (date: Date) => format(date, 'MMMM', { locale: enUS }).toUpperCase();

    downloadRequested.current = new Date();

    const downloadDate = period ? new Date(period.year, period.month - 1) : null;

    downloadMonth.current = downloadDate ? formatMonth(downloadDate) : null;
    downloadYear.current = downloadDate ? getYear(downloadDate) : null;

    csvDownloadRequested({
      csv_download_month: downloadMonth.current,
      csv_download_year: downloadYear.current,
      business_model,
      country,
      report_subtype,
      report_type,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
      tab_name,
    });
  };

  const triggerCsvDownloadFailed = (failureReason: string) => {
    csvDownloadRequestFailed({
      csv_download_month: downloadMonth.current,
      csv_download_year: downloadYear.current,
      failure_reason: failureReason,
      business_model,
      country,
      report_subtype,
      report_type,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
      tab_name,
    });
  };

  const triggerCsvDownloadStarted = () => {
    csvDownloadStarted({
      screen_name,
      business_model,
      country,
      user_type,
      vendor_id,
      vendor_name,
      report_subtype,
      report_type,
      tab_name,
    });
  };

  const triggerCsvDownloadCanceled = () => {
    csvDownloadCanceled({
      screen_name,
      business_model,
      country,
      user_type,
      vendor_id,
      vendor_name,
      report_subtype,
      report_type,
      tab_name,
    });
  };

  return {
    triggerCSVDownloadSuccessful,
    triggerCSVDownloadRequested,
    triggerCsvDownloadFailed,
    triggerCsvDownloadStarted,
    triggerCsvDownloadCanceled,
  };
}
