import AR from '../../assets/flags/AR.svg';
import BR from '../../assets/flags/BR.svg';
import CO from '../../assets/flags/CO.svg';
import DO from '../../assets/flags/DO.svg';
import EC from '../../assets/flags/EC.svg';
import ID from '../../assets/flags/ID.svg';
import MX from '../../assets/flags/MX.svg';
import PA from '../../assets/flags/PA.svg';
import PE from '../../assets/flags/PE.svg';
import PH from '../../assets/flags/PH.svg';
import PY from '../../assets/flags/PY.svg';
import ZA from '../../assets/flags/ZA.svg';

type FlagMap = {
  [key: string]: string;
};

export const flags: FlagMap = {
  AR,
  BR,
  CO,
  DO,
  EC,
  ID,
  MX,
  PA,
  PE,
  PH,
  PY,
  ZA,
};
