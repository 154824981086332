import useSegment from 'Hooks/useSegment/useSegment';
import { useSegmentCommonProps } from '../useSegmentCommonProps/useSegmentCommonProps';
import {
  FilterInteractionEventReturn,
  FilterInteractionPayload,
  FilterSelected,
} from './useFilterInteractionEvent.types';

const filterSelectedMapping: Record<string, FilterSelected> = {
  '30 dias': FilterSelected['30_DAYS'],
  '90 dias': FilterSelected['90_DAYS'],
  'Por PDV': FilterSelected.PER_POC,
  'Por Pedido': FilterSelected.PER_ORDER,
  State: FilterSelected.STATE,
  MTD: FilterSelected.MTD,
  MoM: FilterSelected.MOM,
  SKU: FilterSelected.SKU,
  CD: FilterSelected.DC,
  Estado: FilterSelected.STATE,
  DDC: FilterSelected.DC,
  'Per Order': FilterSelected.PER_ORDER,
  'Per POC': FilterSelected.PER_POC,
  '30 días': FilterSelected['30_DAYS'],
  '90 días': FilterSelected['90_DAYS'],
  '30 days': FilterSelected['30_DAYS'],
  '90 days': FilterSelected['90_DAYS'],
};

export default function useFilterInteractionEvent(): FilterInteractionEventReturn {
  const {
    filtersInteraction,
    dataLoadedRenderingProcess,
    reportRenderedRenderingProcess,
    rederingStartedRenderingProcess,
  } = useSegment();
  const {
    screen_name,
    business_model,
    country,
    user_type,
    vendor_id,
    vendor_name,
    report_subtype,
    report_type,
  } = useSegmentCommonProps();

  const triggerFilterInteraction = ({
    analytics_component_id,
    analytics_component_name,
    filter_selected,
  }: FilterInteractionPayload) => {
    if (analytics_component_name.startsWith('Filter ')) {
      filtersInteraction({
        analytics_component_category: null,
        filter_selected: filterSelectedMapping[filter_selected] || null,
        searched_word: null,
        analytics_component_id,
        analytics_component_name,
        screen_name,
        business_model,
        country,
        report_subtype,
        report_type,
        user_type,
        vendor_id,
        vendor_name,
      });
    }
  };

  const triggerDataLoadedRendering = () => {
    dataLoadedRenderingProcess({
      business_model,
      country,
      report_subtype,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
      report_type,
    });
  };

  const triggerReportRenderedProcess = () => {
    reportRenderedRenderingProcess({
      business_model,
      country,
      report_subtype,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
      report_type,
    });
  };

  const triggerRenderingStartedRenderingProcess = () => {
    rederingStartedRenderingProcess({
      business_model,
      country,
      report_type,
      report_subtype,
      screen_name,
      user_type,
      vendor_id,
      vendor_name,
    });
  };

  return {
    triggerFilterInteraction,
    triggerDataLoadedRendering,
    triggerReportRenderedProcess,
    triggerRenderingStartedRenderingProcess,
  };
}
