import { useGetCluster } from 'admin-portal-shared-services';
import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';

interface ClusterContextType {
  cluster: string;
  setCluster: (cluster: string) => void;
}

export const ClusterContext = createContext<ClusterContextType>({
  cluster: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCluster: () => {},
});

interface ClusterProviderProps {
  children: React.ReactNode;
}

export function ClusterProvider({ children }: ClusterProviderProps): JSX.Element {
  const initialCluster = useGetCluster();

  const getInitialCluster = () => {
    const savedCluster = localStorage.getItem('cluster');
    const savedTimestamp = localStorage.getItem('clusterTimestamp');
    const currentTimestamp = new Date().setHours(0, 0, 0, 0);

    if (savedTimestamp && Number(savedTimestamp) < currentTimestamp) {
      localStorage.removeItem('cluster');
      localStorage.removeItem('clusterTimestamp');
      return initialCluster;
    }

    return savedCluster ?? initialCluster;
  };

  const [cluster, setCluster] = useState<string>(getInitialCluster());

  useEffect(() => {
    localStorage.setItem('cluster', cluster);
    const timestamp = new Date().setHours(0, 0, 0, 0).toString();
    localStorage.setItem('clusterTimestamp', timestamp);
  }, [cluster]);

  const contextValue = useMemo(() => ({ cluster, setCluster }), [cluster]);

  return <ClusterContext.Provider value={contextValue}>{children}</ClusterContext.Provider>;
}

export const useClusterContext = (): ClusterContextType => useContext(ClusterContext);
